import {
  LOAD_ALERTS_RECT_ACTION,
  LOAD_ALERTS_RECT_ACTION_SUCCESS,
  LOAD_ALERTS_RECT_ACTION_ERROR
} from "../actions/types";

export const initialState = {
  loading: true,
  data: []
};

function alertsRectActionReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_ALERTS_RECT_ACTION:
      return {
        ...state,
        data: [],
        loading: true,
        error: false
      };
    case LOAD_ALERTS_RECT_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data
      };
    case LOAD_ALERTS_RECT_ACTION_ERROR:
      return {
        ...state,
        data: [],
        loading: true,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
}

export default alertsRectActionReducer;
