import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Icon from "../../theme/icon";
import UnitStatusCard from "./UnitStatusCard";

function UnitSummary(props) {
  const { match, data } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <div className={classes.header}>
        <Typography
          variant="h4"
          color="inherit"
          style={{ fontVariant: "small-caps" }}
        >
          Unit Summary
        </Typography>
      </div>
      <div className={classes.container}>
        {data.map((item, key) => {
          let reTitle = "";
          let redirect = "";
          reTitle = item["Unit Alias"] ? item["Unit Alias"] : item["Unit Name"];
          redirect = match.params.id + "/unit/" + item["Unit Id"];

          return (
            <Link
              to={redirect}
              style={{ textDecoration: "none" }}
              className={`${
                item.State === "OUT_OF_RANGE" ? classes.reorder : ""
              }`}
            >
              <UnitStatusCard
                icon={<Icon icon="unit" size="40" color="white" />}
                value={item.Value}
                unit={item.Unit}
                status={item.State}
                title={reTitle}
              />
            </Link>
          );
        })}
      </div>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    height: "100%",
    overflow: "hidden",
    position: "relative"
  },
  header: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "30px",
    backgroundColor: "black",
    color: "white",
    padding: "10px 0",
    textAlign: "center",
    zIndex: 1
  },
  container: {
    marginTop: "50px",
    height: "calc(100% - 50px)",
    overflow: "scroll",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10
  },
  reorder: {
    order: -1
  }
}));

export default withRouter(UnitSummary);
