import React, { useState } from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import { Button, Typography, CircularProgress } from "@material-ui/core";
import { forwardRef } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import jsPDF from "jspdf";
import "jspdf-autotable";

import EditIcon from "@material-ui/icons/Edit";

import ArrowDownward from "@material-ui/icons/ArrowDownward";

import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";

import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";

import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";

import AckPopup from "./AlertsAction/AckPopup";
import AckPopupWithReason from "./AlertsAction/AckPopupWithReason";
import RectPopup from "./AlertsAction/RectPopup";
import RectPopupWithReason from "./AlertsAction/RectPopupWithReason";
import { makeStyles } from "@material-ui/core/styles";

const tableIcons = {
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),

  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

function AlertsTableNew(props) {
  const classes = useStyles();

  const [isAckOpen, setAckOpen] = useState(false);
  const [isAckWithReasonOpen, setAckWithReasonOpen] = useState(false);
  const [isRectOpen, setRectOpen] = useState(false);
  const [isRectWithReasonOpen, setRectWithReasonOpen] = useState(false);

  const [id, setID] = useState(null);
  const [etaDate, setEtaDate] = useState(null);
  const [action, setAction] = useState(null);
  const [reason, setReason] = useState(null);

  const handleAck = (id, eta = null) => {
    setID(id);
    setEtaDate(eta);
    setAckOpen(true);
  };
  const handleRect = (id, action = null) => {
    setID(id);
    setAction(action);
    setRectOpen(true);
  };

  const handleAckWithEta = (id, eta, reason = null) => {
    setID(id);
    setEtaDate(eta);
    setReason(reason);
    setAckWithReasonOpen(true);
  };

  const handleRectWithAction = (id, action, reason = null) => {
    setID(id);
    setAction(action);
    setReason(reason);
    setRectWithReasonOpen(true);
  };

  const handleClose = () => {
    setAckOpen(false);
    setRectOpen(false);
    setAckWithReasonOpen(false);
    setRectWithReasonOpen(false);
  };

  if (props.alertsData) {
    var tableData = props.alertsData.map((row, key) => {
      return {
        alert_status: row.alert_status,
        action: (
          <>
            {row.alert_status === "OPEN"
              ? props.accessAllowAck && (
                  <>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      disabled={props.accessReadOnly}
                      onClick={() => {
                        !props.accessReadOnly && handleAck(row.alert_id);
                      }}
                    >
                      <Typography>ACK</Typography>
                    </Button>
                  </>
                )
              : row.alert_status === "ACK"
              ? props.accessAllowRect && (
                  <>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      disabled={props.accessReadOnly}
                      onClick={() => {
                        !props.accessReadOnly && handleRect(row.alert_id);
                      }}
                    >
                      <Typography>RECT</Typography>
                    </Button>
                  </>
                )
              : null}
          </>
        ),
        location_name: row.location_name,
        unit_name: row.unit_name,
        alert_type: row.alert_type,
        client: row.client,
        metric_name: row.metric_name,
        target: row.target,
        actual: row.actual,
        percent_dev: row.percent_dev,
        alert_date:
          row.alert_date &&
          new Date(row.alert_date).toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          }),
        ack_date:
          row.ack_date &&
          new Date(row.ack_date).toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          }),
        ack_by_name: row.ack_by_name,
        expected_rect_time: (
          <>
            {row.expected_rect_time &&
              new Date(row.expected_rect_time).toLocaleString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: true,
              })}
            {row.alert_status === "ACK" && props.accessAllowAck && (
              <Button
                size="small"
                style={{ minWidth: "unset" }}
                disabled={props.accessReadOnly}
                onClick={() => {
                  !props.accessReadOnly &&
                    (row.expected_rect_time
                      ? handleAckWithEta(
                          row.alert_id,
                          row.expected_rect_time,
                          row.alert_eta_change_reason
                        )
                      : handleAck(row.alert_id, row.expected_rect_time));
                }}
              >
                <EditIcon fontSize="small" />
              </Button>
            )}
          </>
        ),
        alert_eta_change_reason: row.alert_eta_change_reason,
        rect_date:
          row.rect_date &&
          new Date(row.rect_date).toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          }),
        rect_by_name: row.rect_by_name,
        rect_action: (
          <>
            {row.rect_action}
            {row.alert_status === "RECT" && props.accessAllowRect && (
              <Button
                size="small"
                style={{ minWidth: "unset" }}
                disabled={props.accessReadOnly}
                onClick={() => {
                  !props.accessReadOnly &&
                    (row.rect_action
                      ? handleRectWithAction(
                          row.alert_id,
                          row.rect_action_id,
                          row.alert_rect_action_change_reason
                        )
                      : handleRect(row.alert_id, row.rect_action_id));
                }}
              >
                <EditIcon fontSize="small" />
              </Button>
            )}
          </>
        ),
        alert_rect_action_change_reason: row.alert_rect_action_change_reason,
        ack_days: row.ack_days,
        rect_days: row.rect_days,
      };
    });
  }
  return (
    <div className={classes.root}>
      <AckPopup
        open={isAckOpen}
        handleClose={handleClose}
        id={id}
        etaDate={etaDate}
      />
      <AckPopupWithReason
        open={isAckWithReasonOpen}
        handleClose={handleClose}
        id={id}
        etaDate={etaDate}
        reason={reason}
      />
      <RectPopup
        open={isRectOpen}
        handleClose={handleClose}
        id={id}
        action={action}
      />
      <RectPopupWithReason
        open={isRectWithReasonOpen}
        handleClose={handleClose}
        id={id}
        action={action}
        reason={reason}
      />
      {props.error ? (
        <div
          style={{
            boxSizing: "border-box",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ERROR!!
        </div>
      ) : props.loading ? (
        <div
          style={{
            boxSizing: "border-box",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="black" />
        </div>
      ) : (
        <Paper className={classes.paper}>
          <ThemeProvider theme={theme}>
            <MaterialTable
              icons={tableIcons}
              options={{
                headerStyle: { backgroundColor: "black", color: "white" },

                rowStyle: (rowData) => ({
                  backgroundColor: rowData.tableData.id % 2 ? "#EEE" : "#FFF",
                }),
                exportButton: true,
                exportAllData: true,
                exportPdf: (columns, data) => {
                  let columnTitles = columns
                    .map((columnDef) => columnDef.title)
                    .filter(
                      (item) =>
                        item !== "Action" &&
                        item !== "Expected Rectification Date" &&
                        item !== "Rectification Action"
                    );

                  let pdfData = data.map((rowData) =>
                    columns
                      .filter(
                        (i) =>
                          i.title !== "Action" &&
                          i.title !== "Expected Rectification Date" &&
                          i.title !== "Rectification Action"
                      )
                      .map((columnDef) => rowData[columnDef.field])
                  );

                  const doc = new jsPDF("l", "cm", "a3");
                  doc.autoTable({
                    head: [columnTitles],
                    body: pdfData,
                  });

                  doc.save(`alerts_data.pdf`);
                },
                draggable: false,
              }}
              title=""
              columns={[
                {
                  title: props.alertsHeading.alert_status,
                  field: "alert_status",
                },
                {
                  title: "Action",
                  field: "action",
                },
                {
                  title: props.alertsHeading.location_name,
                  field: "location_name",
                },
                {
                  title: props.alertsHeading.unit_name,
                  field: "unit_name",
                },
                {
                  title: props.alertsHeading.alert_type,
                  field: "alert_type",
                },
                {
                  title: props.alertsHeading.client,
                  field: "client",
                },
                {
                  title: props.alertsHeading.metric_name,
                  field: "metric_name",
                },
                {
                  title: props.alertsHeading.target,
                  field: "target",
                },
                {
                  title: props.alertsHeading.actual,
                  field: "actual",
                },
                {
                  title: props.alertsHeading.percent_dev,
                  field: "percent_dev",
                },
                {
                  title: props.alertsHeading.alert_date,
                  field: "alert_date",
                },
                {
                  title: props.alertsHeading.ack_date,
                  field: "ack_date",
                },
                {
                  title: props.alertsHeading.ack_by_name,
                  field: "ack_by_name",
                },
                {
                  title: props.alertsHeading.expected_rect_time,
                  field: "expected_rect_time",
                },

                {
                  title: props.alertsHeading.alert_eta_change_reason,
                  field: "alert_eta_change_reason",
                },
                {
                  title: props.alertsHeading.rect_date,
                  field: "rect_date",
                },
                {
                  title: props.alertsHeading.rect_by_name,
                  field: "rect_by_name",
                },
                {
                  title: props.alertsHeading.rect_action,
                  field: "rect_action",
                },
                {
                  title: props.alertsHeading.alert_rect_action_change_reason,
                  field: "alert_rect_action_change_reason",
                },
                {
                  title: props.alertsHeading.ack_days,
                  field: "ack_days",
                },
                {
                  title: props.alertsHeading.rect_days,
                  field: "rect_days",
                },
              ]}
              data={tableData}
            />
          </ThemeProvider>
        </Paper>
      )}
    </div>
  );
}

const theme = createMuiTheme({
  overrides: {
    MuiTableSortLabel: {
      root: {
        color: "#fff",
        "&:focus": {
          color: "#aaa",
        },
        "&:hover": {
          color: "#aaa",
        },
      },
      active: {
        color: "#aaa !important",
      },
      icon: {
        color: "#aaa !important",
      },
    },
  },
});

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  paper: {
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  head: {
    backgroundColor: "black",
    color: "white",
  },
  wrap: {
    whiteSpace: "nowrap",
  },
});

const mapStateToProps = (state) => {
  return {
    error: state.alertsTable.error,
    loading: state.alertsTable.loading,
    alertsHeading: state.alertsTable.alertsHeading,
    alertsData: state.alertsTable.alertsData,
    // access
    accessAllowAck: state.roleAccess.allowAck,
    accessAllowRect: state.roleAccess.allowRect,
    accessReadOnly: state.roleAccess.readOnly,
  };
};

export default connect(mapStateToProps, null)(AlertsTableNew);
