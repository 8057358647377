import {
  LOAD_METRICREPORT,
  LOAD_METRICREPORT_SUCCESS,
  LOAD_METRICREPORT_ERROR
} from "../actions/types";

export const initialState = {
  loading: true,
  error: false
};

function metricReportReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_METRICREPORT:
      return {
        ...state,
        error: false,
        loading: true
      };
    case LOAD_METRICREPORT_SUCCESS:
      const url = window.URL.createObjectURL(new Blob([action.payload.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        action.payload.filename
          ? action.payload.filename
          : "file-" + action.payload.sensorId + "." + action.payload.file_type
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return {
        ...state,
        error: false,
        loading: false
      };
    case LOAD_METRICREPORT_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.payload.errorMessage,
        loading: true
      };
    default:
      return state;
  }
}

export default metricReportReducer;
