import {
  LOAD_METRICCHART,
  LOAD_METRICCHART_SUCCESS,
  LOAD_METRICCHART_ERROR
} from "../actions/types";

export const initialState = {
  start_time: null,
  end_time: null,
  interval: null,
  loading: true,
  updated: false,
  error: false,
  is_warehouse_level_unit: null,
  location_id: null,
  unit_id: null,
  location_name: null,
  unit_name: null,
  metric_name: null,
  data: [],
  threshold: {
    lower: null,
    upper: null
  },
  control_limits: {
    LCL: null,
    UCL: null
  }
};

function metricChartReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_METRICCHART:
      return {
        ...state,
        start_time: action.payload.start_time,
        end_time: action.payload.end_time,
        interval: action.payload.interval,
        updated: false,
        error: false
        // loading: true // fix: disabled temporarily (loading screen disturbs the view)
      };
    case LOAD_METRICCHART_SUCCESS:
      return {
        ...state,
        error: false,
        updated: true,
        loading: false,
        is_warehouse_level_unit: action.payload.is_warehouse_level_unit,
        location_id: action.payload.location_id,
        unit_id: action.payload.unit_id,
        location_name: action.payload.location_name,
        unit_name: action.payload.unit_name,
        metric_name: action.payload.metric_name,
        data: action.payload.dataset,
        threshold: action.payload.threshold,
        control_limits: action.payload.control_limits
      };
    case LOAD_METRICCHART_ERROR:
      return {
        ...state,
        location_id: null,
        unit_id: null,
        location_name: null,
        unit_name: null,
        metric_name: null,
        is_warehouse_level_unit: null,
        data: [],
        threshold: {
          lower: null,
          upper: null
        },
        control_limits: {
          LCL: null,
          UCL: null
        },
        error: true,
        errorMessage: action.payload.errorMessage,
        updated: false,
        loading: true
      };
    default:
      return state;
  }
}

export default metricChartReducer;
