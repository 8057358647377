import React, { useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { messaging } from "./firebase-push";

import { loadRoleAccess } from "./actions/loadRoleAccess";
import { loadLocations } from "./actions/loadLocations";
import { loadNotifications } from "./actions/loadNotifications";

const AppStartup = (props) => {
  const { loadRoleAccess, loadLocations, loadNotifications } = props;

  useEffect(() => {
    // Push Notification
    messaging
      .requestPermission()
      .then(async function () {
        const pushToken = await messaging.getToken();

        if (pushToken !== localStorage.getItem("pushToken")) {
          localStorage.setItem("pushToken", pushToken);

          const token = localStorage.getItem("token");
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          };
          const request = {
            subscription_token: { device_key: `${pushToken}` },
            subscription_type: "firebase",
          };

          axios.post(
            `${process.env.REACT_APP_API_URL}/api/vapid/subscribe`,
            request,
            {
              headers,
            }
          );
        }
      })
      .catch(function (err) {
        console.log("Unable to get permission to notify.", err);
      });

    loadRoleAccess();
    loadLocations();
    loadNotifications(); // Notifications Web Socket
  }, [loadRoleAccess, loadLocations, loadNotifications]);

  return <>{props.children}</>;
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadRoleAccess: () => {
      dispatch(loadRoleAccess());
    },
    loadLocations: () => {
      dispatch(loadLocations());
    },
    loadNotifications: () => {
      dispatch(loadNotifications());
    },
  };
};

export default connect(null, mapDispatchToProps)(AppStartup);
