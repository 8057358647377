import React, { useState, useEffect } from "react";
import { CssBaseline, Grid, Paper, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import CustomImgList from "./CustomImages";
import Logo from "../../assets/genesis-logo.png";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loginStatus, setLoginStatus] = useState("");
  const [redirect, setRedirect] = useState(false);

  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    let random = Math.floor(Math.random() * CustomImgList.length);
    setImageURL(CustomImgList[random]);
  }, []);

  // useEffect(() => {
  //   if(localStorage.getItem("token")){
  //   }
  // }, username);

  const handleSubmit = event => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/login`, {
        username: username,
        password: password
      })
      .then(({ data }) => {
        if (data.token != null) {
          localStorage.setItem("token", data.token);
          localStorage.setItem("username", data.username);
          localStorage.setItem("role", data.role);
          setLoginStatus("Logging in");
          setRedirect(true);
        } else {
          console.error("Invalid User");
          setUsername("");
          setPassword("");
        }
      })
      .catch(error => {
        // console.log(error);
        if (error.response) setLoginStatus(error.response.data.message);
      });

    event.preventDefault();
  };

  const classes = useStyles();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login | Genesis</title>
      </Helmet>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          className={classes.image}
          style={{ backgroundImage: `url(${imageURL})` }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div className={classes.paper}>
            <img className={classes.logo} src={Logo} alt="GENESIS" />
            <form className={classes.form} onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                // name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                // name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                // color="primary"
                style={{ backgroundColor: "black", color: "white" }}
                className={classes.submit}
              >
                Login
              </Button>
              {loginStatus && (redirect ? <Redirect to="/" /> : loginStatus)}
            </form>
          </div>
          <footer
            style={{
              position: "absolute",
              bottom: 5,
              right: 0,
              fontFamily: "Open Sans",
              fontWeight: "bolder"
            }}
          >
            <a
              href="http://phaidelta.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "black" }}
            >
              &copy; phAIdelta &nbsp;
            </a>
          </footer>
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  logo: {
    width: "100%"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default Login;
