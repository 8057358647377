import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";

const Component = (props) => {
  const [allowedLabels, setallowedLabels] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (props.aging) {
      setChartData([
        props.aging[0],
        props.aging[1],
        props.aging[2],
        props.aging["2+"],
      ]);
      if (props.aging["2+"] !== null) {
        setallowedLabels(["0 Day", "1 Day", "2 Day", "> 2 Days"]);
      } else if (props.aging[2] !== null) {
        setallowedLabels(["0 Day", "1 Day", "2 Day"]);
      } else if (props.aging[1] !== null) {
        setallowedLabels(["0 Day", "1 Day"]);
      } else if (props.aging[0] !== null) {
        setallowedLabels(["0 Day"]);
      }
    }
  }, [props.aging]);

  const data = {
    labels: allowedLabels,
    datasets: [
      {
        label: "Aging",
        backgroundColor: "#7D7D7D",
        borderColor: "#7D7D7D",
        borderWidth: 1,
        data: chartData,
      },
    ],
  };
  const options = {
    legend: {
      display: false,
      position: "bottom",
    },
    plugins: {
      datalabels: {
        color: "#ffffff",
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    maintainAspectRatio: false,
  };

  return <Bar data={data} height={150} options={options} />;
};

export default Component;
