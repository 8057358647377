import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

function WarehouseStatusCard(props) {
  const { critical, status, icon, value, unit, title } = props;
  const classes = useStyles();

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        m={1}
        width={250}
        boxShadow={5}
        className={[
          classes.statusCard,
          critical
            ? status === "CRITICAL_ACTIVE"
              ? classes.reorderEmergencyCritical
              : classes.reorder
            : ""
        ]}
      >
        <Box
          bgcolor={`status.${status}`}
          color="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.statusIconContainer}
        >
          {icon}
        </Box>
        <Box
          bgcolor={`status.${status}Faded`}
          display="flex"
          position="relative"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-evenly"
          className={classes.statusContent}
        >
          <Typography
            variant="h1"
            style={{
              color: "white",
              fontSize: props.fontSize ? props.fontSize : 28,
              lineHeight: "30px",
              flex: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {value}
            <span style={{ fontSize: 12, fontWeight: 300, marginBottom: -15 }}>
              &nbsp;{unit}
            </span>
          </Typography>
          <Box
            style={{
              flex: 1,
              position: "absolute",
              bottom: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Typography
              variant="h6"
              style={{
                color: "white",
                whiteSpace: "nowrap"
                // wordBreak: "break-all",
                // textAlign: "center"
              }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  reorder: {
    order: -1
  },
  reorderEmergencyCritical: {
    order: -2
  },
  statusCard: {
    borderRadius: "10px"
  },
  statusIconContainer: {
    height: "100px",
    flex: 4,
    minWidth: "85px",
    wordBreak: "break-all",
    textAlign: "center",
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px"
  },
  statusContent: {
    height: "100px",
    flex: 7,
    minWidth: "165px",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px"
  }
}));

export default WarehouseStatusCard;
