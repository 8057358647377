import {
  LOAD_ROLEACCESS,
  LOAD_ROLEACCESS_SUCCESS,
  LOAD_ROLEACCESS_ERROR
} from "./types";

export const loadRoleAccess = () => {
  return dispatch => {
    dispatch(loading());
    const role = localStorage.getItem("role");
    if (role) {
      dispatch(success({ role }));
    } else {
      console.log("ERROR! [roleAccess] User role is undefined");
      const payload = {
        errorMessage: "User role is undefined"
      };
      dispatch(error(payload));
    }
  };
};

const loading = () => ({
  type: LOAD_ROLEACCESS
});

const success = data => ({
  type: LOAD_ROLEACCESS_SUCCESS,
  payload: {
    ...data
  }
});

const error = data => ({
  type: LOAD_ROLEACCESS_ERROR,
  payload: {
    ...data
  }
});
