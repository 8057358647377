import {
  LOAD_LOCATIONS,
  LOAD_LOCATIONS_SUCCESS,
  LOAD_LOCATIONS_ERROR
} from "../actions/types";

export const initialState = {
  loading: true,
  data: []
};

function locationsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_LOCATIONS:
      return {
        ...state,
        data: [],
        loading: true,
        error: false
      };
    case LOAD_LOCATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false
      };
    case LOAD_LOCATIONS_ERROR:
      return {
        ...state,
        data: [],
        loading: true,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
}

export default locationsReducer;
