import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Toolbar, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import TopBar from "./TopBar";
import NavBar from "./Navbar";

// import "./dialogflow.css";

function Dashboard({ children, ...props }) {
  const { match, path, documentTitle, title } = props;
  const classes = useStyles();
  const [toggleDrawer, setToggleDrawer] = useState(false);

  useEffect(() => {
    setToggleDrawer(false);
  }, [match.params, path]);

  const handleToggle = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setToggleDrawer(open);
  };

  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{documentTitle} | Genesis</title>
        {/* <script src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"></script> */}
      </Helmet>
      <TopBar handleToggle={handleToggle} title={title} />
      <NavBar open={toggleDrawer} handleToggle={handleToggle} />
      <Toolbar />
      <main className={classes.main}>{children}</main>
      {/* <df-messenger
        chat-icon="ef9eb6d6-9bab-4e51-95ac-1d5e7beaf85b_x.png"
        intent="WELCOME"
        chat-title="ABOT"
        agent-id="b901bc17-1c2e-4888-9f24-5a1e551fc57b"
        language-code="en"
      ></df-messenger> */}
      <footer className={classes.footer}>
        <Typography
          color="primary"
          style={{ fontFamily: "Open Sans", fontWeight: "bold" }}
        >
          <Link
            href="http://phaidelta.com/"
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
            style={{ textDecoration: "none" }}
          >
            &copy; phAIdelta &nbsp;
          </Link>
        </Typography>
      </footer>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    minHeight: "100vh",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh -  64px)",
    overflow: "hidden",
    flex: 1,
    backgroundColor: "lightgray",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  footer: {
    position: "absolute",
    bottom: 3,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      bottom: 0,
      // position: "relative",
      // alignSelf: "flex-end"
    },
  },
}));

export default withRouter(Dashboard);
