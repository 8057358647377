import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Line, Bar } from "react-chartjs-2";

import "chartjs-plugin-datalabels";
import "chartjs-plugin-zoom";
import "chartjs-plugin-trendline";

import ChartLayout from "./ChartLayout";
import { loadMetricChart } from "../../actions/loadMetricChart";
import { loadMetricReport } from "../../actions/loadMetricReport";
import {
  getTimeRange,
  getAllowedIntervals,
} from "../../helpers/timeRangeSelectionHelper";

function Analytics(props) {
  const classes = useStyles();
  const [zoomOption, setZoomOption] = useState(false);
  const [data, setData] = useState();
  const [data2, setData2] = useState();
  const [timeRangeOption, setTimeRangeOption] = useState("TODAY");
  const availableIntervals = ["1D", "1H"];
  const [allowedIntervals, setAllowedIntervals] = useState([]);
  const [dataInterval, setDataInterval] = useState("15M");
  const [chartInterval, setChartInterval] = useState({
    interval: 15,
    intervalUnit: "minute",
  });
  const [chartTime, setChartTime] = useState({ start: null, end: null });
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const timerEff = timer;
    return () => {
      clearInterval(timerEff);
    };
  }, [timer]);

  useEffect(() => {
    handleTimeRangeOption("TODAY");
  }, []);

  useEffect(() => {
    if (props.updated) {
      setChartTime({ start: props.start_time, end: props.end_time });
      switch (dataInterval) {
        case "15M":
          setChartInterval({
            interval: 15,
            intervalUnit: "minute",
          });
          break;
        case "30M":
          setChartInterval({
            interval: 30,
            intervalUnit: "minute",
          });
          break;
        case "1H":
          setChartInterval({
            interval: 1,
            intervalUnit: "hour",
          });
          break;
        case "1D":
          setChartInterval({
            interval: 1,
            intervalUnit: "day",
          });
          break;
        default:
      }
    }
  }, [dataInterval, props.updated, props.start_time, props.end_time]);

  const runLoadChart = (start_time, end_time, interval, option = null) => {
    props.loadMetricChart(
      props.match.params.sid,
      start_time,
      end_time,
      interval
    );
    if (option) {
      const t = setTimeout(() => {
        handleTimeRangeOption(option, interval);
      }, 120000);
      setTimer(t);
    } else setTimer(0);
  };

  const handleStartDateChange = (d) => {
    setTimeRangeOption("");
    let {
      allowedIntervals: allIntr,
      defaultInterval: defaultIntr,
    } = getAllowedIntervals(availableIntervals, d, props.end_time);
    setAllowedIntervals(allIntr);
    setDataInterval(defaultIntr);
    runLoadChart(d, props.end_time, defaultIntr);
  };

  const handleEndDateChange = (d) => {
    setTimeRangeOption("");
    let {
      allowedIntervals: allIntr,
      defaultInterval: defaultIntr,
    } = getAllowedIntervals(availableIntervals, props.start_time, d);
    setAllowedIntervals(allIntr);
    setDataInterval(defaultIntr);
    runLoadChart(props.start_time, d, defaultIntr);
  };

  const handleTimeRangeOption = (option, intr = null) => {
    setTimeRangeOption(option);
    let timeRange = getTimeRange(option);
    let {
      allowedIntervals: allIntr,
      defaultInterval: defaultIntr,
    } = getAllowedIntervals(
      availableIntervals,
      timeRange.start_time,
      timeRange.end_time
    );
    setAllowedIntervals(allIntr);
    setDataInterval(defaultIntr);
    runLoadChart(
      timeRange.start_time,
      timeRange.end_time,
      intr ? intr : defaultIntr,
      ["TODAY", "WEEK", "MONTH"].includes(option) ? option : null
    );
  };

  const handleDataInterval = (intr) => {
    setDataInterval(intr);
    runLoadChart(
      props.start_time,
      props.end_time,
      intr,
      ["TODAY", "WEEK", "MONTH"].includes(timeRangeOption)
        ? timeRangeOption
        : null
    );
  };

  useEffect(() => {
    const data = {
      labels: props.labels,
      datasets: [
        {
          type: "line",
          xAxisID: "x-axis-1",
          yAxisID: "y-axis-1",
          label: "Run Hours",
          backgroundColor: "rgba(0,0,0,0)",
          borderColor: "rgba(0,0,0,1)",
          borderWidth: 2,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: props.chartData,
          pointBackgroundColor: [],
          pointBorderWidth: 0.3,
          pointStyle: "circle",
        },
        {
          type: "line",
          xAxisID: "x-axis-2",
          yAxisID: "y-axis-1",
          label: "UCL (" + props.control_limits.UCL + ")",
          pointStyle: "line",
          borderDash: [15, 3, 3, 3],
          borderWidth: 2,
          borderColor: "rgba(0,0,255,1)",
          backgroundColor: "rgba(0,0,0,0)",
          fill: true,
          data: [
            { x: -100, y: props.control_limits.UCL },
            { x: 100, y: props.control_limits.UCL },
          ],
        },
        {
          type: "line",
          xAxisID: "x-axis-2",
          yAxisID: "y-axis-1",
          label: "LCL (" + props.control_limits.LCL + ")",
          pointStyle: "line",
          borderDash: [15, 3, 3, 3],
          borderWidth: 2,
          borderColor: "rgba(0,0,255,1)",
          backgroundColor: "rgba(0,0,0,0)",
          fill: true,
          data: [
            { x: -100, y: props.control_limits.LCL },
            { x: 100, y: props.control_limits.LCL },
          ],
        },
        {
          type: "line",
          xAxisID: "x-axis-2",
          yAxisID: "y-axis-1",
          label: "Upper Limit (" + props.threshold.upper + ")",
          pointStyle: "line",
          borderDash: [5, 5],
          borderWidth: 2,
          borderColor: "rgba(255,0,0,1)",
          backgroundColor: "rgba(0,0,0,0)",
          fill: true,
          data: [
            { x: -100, y: props.threshold.upper },
            { x: 100, y: props.threshold.upper },
          ],
        },
        {
          type: "line",
          xAxisID: "x-axis-2",
          yAxisID: "y-axis-1",
          label: "Lower Limit (" + props.threshold.lower + ")",
          pointStyle: "line",
          borderDash: [5, 5],
          borderWidth: 2,
          borderColor: "rgba(255,0,0,1)",
          backgroundColor: "rgba(0,0,0,0)",
          fill: true,
          data: [
            { x: -100, y: props.threshold.lower },
            { x: 100, y: props.threshold.lower },
          ],
        },
      ],
    };

    data.datasets[0] &&
      data.datasets[0].data.forEach((item, key) => {
        if (props.threshold.upper && item > props.threshold.upper)
          data.datasets[0].pointBackgroundColor[key] = "red";
        else if (props.threshold.lower && item < props.threshold.lower)
          data.datasets[0].pointBackgroundColor[key] = "red";
        else data.datasets[0].pointBackgroundColor[key] = "green";
      });

    const data2 = {
      labels: props.labels,
      datasets: [
        {
          type: "bar",
          label: "Cumulative Run Hours",
          backgroundColor: "#000",
          // hoverBackgroundColor: "black",
          data: props.chartData2,
          trendlineLinear: {
            style: "rgba(0,0,0, .8)",
            lineStyle: "solid",
            width: 2,
          },
        },
      ],
    };

    setData(data);
    setData2(data2);
  }, [props.chartData, props.chartData2]);

  const options = {
    spanGaps: true,
    animation: {
      duration: 500,
    },
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: true,
      position: "top",
    },
    tooltips: {
      filter: function (tooltipItem) {
        return tooltipItem.datasetIndex === 0;
      },
    },
    plugins: {
      datalabels: {
        display: false,
        align: "top",
        color: "black",
      },
      zoom: {
        pan: {
          enabled: zoomOption,
          mode: "xy",
          // rangeMin: {
          //   x: timeRange.start,
          //   y: data
          //     ? Math.min(
          //         ...data.datasets[0].data,
          //         props.control_limits.LCL,
          //         props.threshold.lower
          //       ) - 5
          //     : 0
          // },
          // rangeMax: {
          //   x: timeRange.end,
          //   y: data
          //     ? Math.max(
          //         ...data.datasets[0].data,
          //         props.control_limits.UCL,
          //         props.threshold.upper
          //       ) + 5
          //     : 1
          // }
        },
        zoom: {
          enabled: zoomOption,
          mode: "xy",
          // rangeMin: {
          //   x: timeRange.start,
          //   y: data
          //     ? Math.min(
          //         ...data.datasets[0].data,
          //         props.control_limits.LCL,
          //         props.threshold.lower
          //       ) + 5
          //     : 1
          // },
          // rangeMax: {
          //   x: timeRange.end,
          //   y: data
          //     ? Math.max(
          //         ...data.datasets[0].data,
          //         props.control_limits.UCL,
          //         props.threshold.upper
          //       ) + 5
          //     : 1
          // }
        },
      },
    },
    scales: {
      xAxes: [
        {
          id: "x-axis-1",
          type: "time",
          distribution: "linear",
          time: {
            unit: chartInterval.intervalUnit,
            stepSize: chartInterval.interval,
            displayFormats: {
              minute: "MMM D h:mm a",
              hour: "MMM D h:mm a",
              day: "MMM D YYYY h:mm a",
            },
          },
          ticks: {
            min: chartTime.start,
            max: chartTime.end,
          },
        },
        {
          id: "x-axis-2",
          gridLines: {
            display: false,
          },
          type: "linear",
          position: "top",
          ticks: {
            display: false,
            min: 0,
            max: 1,
          },
        },
      ],
      yAxes: [
        {
          id: "y-axis-1",
          scaleLabel: {
            display: true,
            labelString: "Run Hours",
            fontColor: "#000",
            lineHeight: 2,
            fontSize: 15,
          },
          ticks: {
            min: data
              ? Math.floor(
                  Math.min(
                    ...data.datasets[0].data
                    // props.control_limits.LCL,
                    // props.threshold.lower
                  )
                ) - 2
              : 0,
            max: data
              ? Math.floor(
                  Math.max(
                    ...data.datasets[0].data
                    // props.control_limits.UCL,
                    // props.threshold.upper
                  )
                ) + 2
              : 1,
            stepSize: 1,
            unitStepSize: 1,
            userCallback: function (label) {
              if (Math.abs(label.toFixed(2)) === label) {
                return label;
              }
            },
          },
        },
      ],
    },
  };

  const options2 = {
    spanGaps: true,
    animation: {
      duration: 500,
    },
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: true,
      position: "top",
    },
    plugins: {
      datalabels: {
        display: false,
        align: "top",
        color: "black",
      },
      zoom: {
        pan: {
          enabled: zoomOption,
          mode: "xy",
        },
        zoom: {
          enabled: zoomOption,
          mode: "xy",
        },
      },
    },
    scales: {
      xAxes: [
        {
          type: "time",
          distribution: "linear",
          time: {
            unit: chartInterval.intervalUnit,
            stepSize: chartInterval.interval,
            displayFormats: {
              minute: "MMM D h:mm a",
              hour: "MMM D h:mm a",
              day: "MMM D YYYY h:mm a",
            },
          },
          ticks: {
            min: chartTime.start,
            max: chartTime.end,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Run Hours",
            fontColor: "#000",
            lineHeight: 2,
            fontSize: 15,
          },
          ticks: {
            // min: data2
            //   ? Math.min(
            //       ...data2.datasets[0].data
            //       // props.control_limits.LCL,
            //       // props.threshold.lower
            //     ) - 1
            //   : 0,
            // max: data2
            //   ? Math.max(
            //       ...data2.datasets[0].data
            //       // props.control_limits.UCL,
            //       // props.threshold.upper
            //     ) + 1
            //   : 1,
            // stepSize: 1,
            // unitStepSize: 1,
            userCallback: function (label) {
              if (Math.abs(label.toFixed(2)) === label) {
                return label;
              }
            },
          },
        },
      ],
    },
  };

  return (
    <ChartLayout
      location_id={props.location_id}
      unit_id={props.unit_id}
      location_name={props.location_name}
      unit_name={props.unit_name}
      metric_name={props.metric_name}
      metrics={props.title}
      dataInterval={props.interval}
      setDataInterval={setDataInterval}
      handleDataInterval={handleDataInterval}
      timeRangeOption={timeRangeOption}
      handleTimeRangeOption={handleTimeRangeOption}
      availableIntervals={availableIntervals}
      allowedIntervals={allowedIntervals}
      startDate={props.start_time}
      endDate={props.end_time}
      handleStartDateChange={handleStartDateChange}
      handleEndDateChange={handleEndDateChange}
      is_warehouse_level_unit={props.is_warehouse_level_unit}
    >
      {props.errorChart ? (
        <div
          style={{
            boxSizing: "border-box",
            height: "100%",
            padding: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.errorChartMessage}
        </div>
      ) : props.loading ? (
        <div
          style={{
            boxSizing: "border-box",
            height: "100%",
            padding: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="black" />
        </div>
      ) : (
        <>
          <Box display="flex" my={1} alignItems="center">
            {props.accessDownloadFiles && (
              <div>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  className={classes.btn}
                  disabled={props.accessReadOnly}
                  onClick={() => {
                    !props.accessReadOnly &&
                      props.loadMetricReport(
                        props.match.params.sid,
                        props.start_time,
                        props.end_time,
                        props.interval,
                        "pdf"
                      );
                  }}
                >
                  Download PDF
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  className={classes.btn}
                  disabled={props.accessReadOnly}
                  onClick={() => {
                    !props.accessReadOnly &&
                      props.loadMetricReport(
                        props.match.params.sid,
                        props.start_time,
                        props.end_time,
                        props.interval,
                        "xlsx"
                      );
                  }}
                >
                  Download XLSX
                </Button>
              </div>
            )}
            <Box display="flex" flex={1} />
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={zoomOption}
                    onChange={() => {
                      setZoomOption(!zoomOption);
                    }}
                  />
                }
                label="Zoom"
              />
            </FormGroup>
          </Box>
          <Line height={400} data={data} options={options} />
          <br />
          <br />
          {/* Temporary fix for chartjs-plugin-trendline plugin problem */}
          {data2.datasets[0].data.length > 0 && (
            <Bar height={50} data={data2} options={options2} />
          )}
        </>
      )}
    </ChartLayout>
  );
}

const useStyles = makeStyles((theme) => ({
  btn: {
    marginLeft: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => {
  // chart data processing
  let labels = [];
  let data = [];
  let data2 = [];
  state.metricChart.data.forEach((item, key) => {
    let date = new Date(item.x);
    date.getTimezoneOffset();
    let formattedDate = date.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    labels.push(formattedDate);
    data.push(item.y);
    data2.push(item.y2);
  });

  return {
    start_time: state.metricChart.start_time,
    end_time: state.metricChart.end_time,
    interval: state.metricChart.interval,
    is_warehouse_level_unit: state.metricChart.is_warehouse_level_unit,
    location_id: state.metricChart.location_id,
    unit_id: state.metricChart.unit_id,
    location_name: state.metricChart.location_name,
    unit_name: state.metricChart.unit_name,
    metric_name: state.metricChart.metric_name,
    labels: labels,
    chartData: data,
    chartData2: data2,
    threshold: state.metricChart.threshold,
    control_limits: state.metricChart.control_limits,
    loading: state.metricChart.loading,
    updated: state.metricChart.updated,
    errorChart: state.metricChart.error,
    errorChartMessage: state.metricChart.errorMessage,
    errorReport: state.metricReport.error,
    errorReportMessage: state.metricReport.errorMessage,
    // access
    accessDownloadFiles: state.roleAccess.downloadFiles,
    accessReadOnly: state.roleAccess.readOnly,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadMetricChart: (id, start_time, end_time, interval) => {
      dispatch(loadMetricChart(id, start_time, end_time, interval));
    },
    loadMetricReport: (id, start_time, end_time, interval, file_type) => {
      dispatch(loadMetricReport(id, start_time, end_time, interval, file_type));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Analytics)
);
