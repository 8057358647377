import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Breadcrumbs, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import { useSnackbar } from "notistack";

import { loadWarehouse } from "../../actions/loadWarehouse";

import DashboardLayout from "../../layout/Dashboard";
import ComponentWrapper from "../../components/ComponentWrapper";
import UnitSummary from "./UnitSummary";
import WarehouseMetrics from "./WarehouseMetrics";

function Warehouse(props) {
  const {
    match,
    title: defaultTitle,
    unitSummary,
    warehouseMetrics,
    loading,
    error,
    errorMessage,
    loadWarehouse: loadWarehouseFn
  } = props;
  const classes = useStyles();
  // const { enqueueSnackbar } = useSnackbar();
  const [title, setTitle] = useState(null);

  useEffect(() => {
    loadWarehouseFn(match.params.id);

    let intr = setInterval(() => {
      loadWarehouseFn(match.params.id);
    }, 120000);
    return () => {
      clearInterval(intr);
    };
  }, [match.params.id, loadWarehouseFn]);

  // useEffect(() => {
  //   if (error && errorMessage) {
  //     enqueueSnackbar(errorMessage, { variant: "error" });
  //   }
  // }, [error]);

  useEffect(() => {
    // Set Page Title
    const TitleBreadcrumb = () => {
      return (
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography style={{ color: "white" }}>
            {unitSummary[0]
              ? unitSummary[0]["Location Alias"]
                ? unitSummary[0]["Location Alias"]
                : unitSummary[0]["Location Name"]
              : defaultTitle + " " + match.params.id}
          </Typography>
        </Breadcrumbs>
      );
    };
    setTitle(TitleBreadcrumb);
    return () => {
      setTitle(null);
    };
  }, [unitSummary, match.params, defaultTitle]);

  return (
    <DashboardLayout title={title} documentTitle="Warehouse Metrics">
      <ComponentWrapper
        loading={loading}
        error={error}
        errorMessage={errorMessage}
      >
        <Grid container spacing={0} style={{ height: "100%" }}>
          <Grid item xs={12} sm="auto" className={classes.gridItemLeft}>
            <UnitSummary data={unitSummary} />
          </Grid>
          <Grid item xs={12} sm="auto" className={classes.gridItemRight}>
            <WarehouseMetrics data={warehouseMetrics} />
          </Grid>
        </Grid>
      </ComponentWrapper>
    </DashboardLayout>
  );
}

const useStyles = makeStyles(theme => ({
  gridItemLeft: {
    height: "100%",
    paddingBottom: "16px",
    paddingTop: "16px",
    paddingLeft: "16px",
    paddingRight: "16px"
  },
  gridItemRight: {
    display: "flex",
    flex: 1,
    height: "100%",
    paddingBottom: "16px",
    paddingTop: "16px",
    paddingRight: "16px"
  },
  paper: {
    height: "100%"
  }
}));

const mapStateToProps = state => {
  return {
    unitSummary: state.warehouse.unitSummary,
    warehouseMetrics: state.warehouse.warehouseMetrics,
    loading: state.warehouse.loading,
    error: state.warehouse.error,
    errorMessage: state.warehouse.errorMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadWarehouse: id => {
      dispatch(loadWarehouse(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Warehouse));
