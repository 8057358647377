import axios from "axios";

import {
  LOAD_ALERTSCHART,
  LOAD_ALERTSCHART_SUCCESS,
  LOAD_ALERTSCHART_ERROR
} from "./types";

export const loadAlertsChart = (start_time, end_time, location_list) => {
  return dispatch => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/alerts/summary`,
        {
          start_time: start_time,
          end_time: end_time,
          loc_ids: location_list
        },
        { headers }
      )
      .then(({ data }) => {
        const payload = {
          data: data
        };
        dispatch(success(payload));
      })
      .catch(err => {
        console.log("ERROR! [loadAlertsChart] " + err.message);
        const payload = {
          errorMessage: err.message
        };
        dispatch(error(payload));
      });
  };
};

const loading = () => ({
  type: LOAD_ALERTSCHART
});

const success = data => ({
  type: LOAD_ALERTSCHART_SUCCESS,
  payload: {
    ...data
  }
});

const error = data => ({
  type: LOAD_ALERTSCHART_ERROR,
  payload: {
    ...data
  }
});
