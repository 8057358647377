import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import DashboardLayout from "../../layout/Dashboard";

import Tree from "./treeView";

function PowerView(props) {
  const classes = useStyles();

  return (
    <DashboardLayout title={props.title} documentTitle="Power">
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.Wrapper}>
          <Paper className={classes.paper}>
            <Tree />
          </Paper>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    height: "100%",
    width: "100%",
    overflow: "hidden"
  },
  root: {
    backgroundColor: "lightgray",
    height: "100%",
    // flex: 1,
    width: "100%"
    // padding: "16px"
  },
  Wrapper: {
    padding: "16px",
    marginBottom: -50
  }
}));

export default PowerView;
