import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { Typography, Breadcrumbs } from "@material-ui/core";

import AlertsLayout from "./AlertsLayoutP";
import AlertsTable from "./AlertsTable";
import AlertsChart from "./AlertsChart";

import { loadAlertsTable } from "../../actions/loadAlertsTable";
import { loadAlertsChart } from "../../actions/loadAlertsChart";

function Alerts(props) {
  const [title, setTitle] = useState(null);
  const [selection, setSelection] = useState([]);
  const [timeRange, setTimeRange] = useState({
    start: null,
    end: null
  });
  const [rangeOption, setRangeOption] = useState("TODAY");

  const handleTimeRange = range => {
    setTimeRange(range);
  };

  useEffect(() => {
    if (timeRange.start && timeRange.end) {
      let loc_ids = [];

      if (selection > 0) {
        loc_ids.push(selection);
      } else {
        loc_ids = props.locationList;
      }
      // Set local time for Alerts Charts and Table
      props.loadAlertsTable(
        moment(timeRange.start).format(),
        moment(timeRange.end).format(),
        loc_ids
      );
      props.loadAlertsChart(
        moment(timeRange.start).format(),
        moment(timeRange.end).format(),
        loc_ids
      );
    }
  }, [timeRange, selection]);

  useEffect(() => {
    // Set Page Title
    const TitleBreadcrumb = () => {
      return (
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography style={{ color: "white" }}>{props.title}</Typography>
        </Breadcrumbs>
      );
    };
    setTitle(TitleBreadcrumb);
    return () => {
      setTitle(null);
    };
  }, []);

  return (
    <AlertsLayout
      title={title}
      timeRange={timeRange}
      handleTimeRange={handleTimeRange}
      selection={selection}
      setSelection={setSelection}
      rangeOption={rangeOption}
      setRangeOption={setRangeOption}
    >
      <AlertsChart rangeOption={rangeOption} />
      <AlertsTable />
    </AlertsLayout>
  );
}

const mapStateToProps = state => {
  var idList = state.locations.data.map(item => {
    return item.id;
  });
  return {
    locationList: idList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadAlertsTable: (start_time, end_time, location_list) => {
      dispatch(loadAlertsTable(start_time, end_time, location_list));
    },
    loadAlertsChart: (start_time, end_time, location_list) => {
      dispatch(loadAlertsChart(start_time, end_time, location_list));
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Alerts));
