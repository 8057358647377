import {
  LOAD_ALERTSCHART,
  LOAD_ALERTSCHART_SUCCESS,
  LOAD_ALERTSCHART_ERROR
} from "../actions/types";

export const initialState = {
  data: {},
  loading: true,
  error: false
};

function alertsChartReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_ALERTSCHART:
      return {
        ...state,
        data: {},
        loading: true,
        error: false
      };
    case LOAD_ALERTSCHART_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false
      };
    case LOAD_ALERTSCHART_ERROR:
      return {
        ...state,
        data: {},
        loading: true,
        error: true
      };
    default:
      return state;
  }
}

export default alertsChartReducer;
