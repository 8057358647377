import React from "react";
import { Box, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

function ComponentWrapper({ children, ...props }) {
  const { loading, error, errorMessage } = props;
  const classes = useStyles();

  if (error)
    return (
      <Box className={classes.container}>
        <Typography>{errorMessage}</Typography>
      </Box>
    );
  else if (loading)
    return (
      <Box className={classes.container}>
        <CircularProgress color="black" />
      </Box>
    );
  else return children;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
  }
}));

export default ComponentWrapper;
