import axios from "axios";
import _ from "lodash";

import {
  LOAD_LOCATIONS,
  LOAD_LOCATIONS_SUCCESS,
  LOAD_LOCATIONS_ERROR
} from "./types";

export const loadLocations = () => {
  return dispatch => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/locations`, { headers })
      .then(({ data }) => {
        if (data.length > 0) {
          var ascdata = _.orderBy(data, "name", "asc");
          const payload = {
            data: ascdata
          };
          dispatch(success(payload));
        } else throw Error("No data found");
      })
      .catch(err => {
        console.log("ERROR! [loadLocations] " + err.message);
        const payload = {
          errorMessage: err.message
        };
        dispatch(error(payload));
      });
  };
};

const loading = () => ({
  type: LOAD_LOCATIONS
});

const success = data => ({
  type: LOAD_LOCATIONS_SUCCESS,
  payload: {
    ...data
  }
});

const error = data => ({
  type: LOAD_LOCATIONS_ERROR,
  payload: {
    ...data
  }
});
