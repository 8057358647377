import axios from "axios";

import {
  LOAD_ALERTS_RECT_ACTION,
  LOAD_ALERTS_RECT_ACTION_SUCCESS,
  LOAD_ALERTS_RECT_ACTION_ERROR
} from "./types";

export const loadAlertsRectAction = () => {
  return dispatch => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/alert_rectification_action`, { headers })
      .then(({ data }) => {
        if (data.length > 0) {
          const payload = {
            data: data
          };
          dispatch(success(payload));
        } else throw Error("No data found");
      })
      .catch(err => {
        console.log("ERROR! [alertsRectAction] " + err.message);
        const payload = {
          errorMessage: err.message
        };
        dispatch(error(payload));
      });
  };
};

const loading = () => ({
  type: LOAD_ALERTS_RECT_ACTION
});

const success = data => ({
  type: LOAD_ALERTS_RECT_ACTION_SUCCESS,
  payload: {
    ...data
  }
});

const error = data => ({
  type: LOAD_ALERTS_RECT_ACTION_ERROR,
  payload: {
    ...data
  }
});
