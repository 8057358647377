import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { Grid, TextField, Typography, Divider } from "@material-ui/core";

import {
  updateEtaOrAction,
  updateStateEtaOrAction
} from "../../../actions/updateAlert";

function AckPopupWithReason(props) {
  const [selectedDate, handleDateChange] = useState(null);
  const [reason, setReason] = useState(null);

  useEffect(() => {
    handleDateChange(props.etaDate);
    setReason(reason);
  }, [props]);

  const handleUpdate = () => {
    props.updateEtaOrAction(props.id, "ACK", selectedDate, reason);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">OPEN -> ACK</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container>
            <Grid item xs={6}>
              <Typography>Expected Rectification Date</Typography>
            </Grid>
            <Grid item xs={6}>
              <KeyboardDateTimePicker
                clearable
                disablePast
                ampm={true}
                fullWidth
                value={selectedDate}
                onChange={handleDateChange}
                inputVariant="outlined"
                format="DD/MM/YYYY HH:mm a"
                helperText={props.etaDate && "(optional)"}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>Reason to Change</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-dense-multiline"
                margin="dense"
                variant="outlined"
                multiline
                fullWidth
                rowsMax="4"
                rows="4"
                helperText="(optional)"
                onChange={e => setReason(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Close
        </Button>
        <Button
          onClick={() => {
            handleUpdate();
            props.handleClose();
          }}
          color="primary"
        >
          ACK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    updateEtaOrAction: (id, state, eta, reason = null) => {
      dispatch(updateEtaOrAction(id, state, eta, reason));
    },
    updateStateEtaOrAction: (id, newState, eta) => {
      dispatch(updateStateEtaOrAction(id, newState, eta));
    }
  };
};

export default connect(null, mapDispatchToProps)(AckPopupWithReason);
