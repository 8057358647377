import axios from "axios";
import moment from "moment";

import {
  LOAD_METRICCHART,
  LOAD_METRICCHART_SUCCESS,
  LOAD_METRICCHART_ERROR
} from "./types";

export const loadMetricChart = (id, start_t, end_t, intr) => {
  return dispatch => {
    dispatch(loading({ start_time: start_t, end_time: end_t, interval: intr }));
    let diffTime = moment.duration(end_t.diff(start_t));
    let diffHrs = diffTime.asHours();
    if (diffHrs > 1440) {
      const payload = { errorMessage: "Error! Date range limit exceeded" };
      dispatch(error(payload));
    } else if (diffHrs < 0) {
      const payload = { errorMessage: "Error! Invalid date range" };
      dispatch(error(payload));
    } else {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };
      const request = {
        start_time: start_t.toDate().toISOString(),
        end_time: end_t.toDate().toISOString(),
        interval: intr
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/history/` + id, request, {
          headers
        })
        .then(({ data }) => {
          if (data.dataset && data.dataset.length > 0) {
            const payload = {
              is_warehouse_level_unit: data.is_warehouse_level_unit,
              location_id: data.location_id,
              unit_id: data.unit_id,
              location_name: data.location_name,
              unit_name: data.unit_name,
              metric_name: data.metric_name,
              dataset: data.dataset,
              threshold: data.threshold,
              control_limits: data.control_limits
            };
            dispatch(success(payload));
          } else throw Error("No data found");
        })
        .catch(err => {
          console.log("ERROR! [loadMetrics] " + err.message);
          const payload = {
            errorMessage: err.message
          };
          dispatch(error(payload));
        });
    }
  };
};

const loading = data => ({
  type: LOAD_METRICCHART,
  payload: {
    ...data
  }
});

const success = data => ({
  type: LOAD_METRICCHART_SUCCESS,
  payload: {
    ...data
  }
});

const error = data => ({
  type: LOAD_METRICCHART_ERROR,
  payload: {
    ...data
  }
});
