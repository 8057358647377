import axios from "axios";

import {
  LOAD_WAREHOUSEDATA,
  LOAD_WAREHOUSEDATA_SUCCESS,
  LOAD_WAREHOUSEDATA_ERROR
} from "./types";

export const loadWarehouse = id => {
  return dispatch => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/metrics/warehouse/` + id, { headers })
      .then(({ data }) => {
        if (
          Array.isArray(data.wv_unit_summary) &&
          Array.isArray(data.wv_warehouse_metrics)
        ) {
          if (
            data.wv_unit_summary.length === 0 &&
            data.wv_warehouse_metrics.length === 0
          ) {
            throw Error("Nothing to display");
          }
          const payload = {
            wv_unit_summary: data.wv_unit_summary,
            wv_warehouse_metrics: data.wv_warehouse_metrics
          };
          dispatch(success(payload));
        } else throw Error("API did not return the expected data");
      })
      .catch(err => {
        console.log("ERROR! [loadWarehouse] " + err.message);
        const payload = {
          errorMessage: err.message
        };
        dispatch(error(payload));
      });
  };
};

const loading = () => ({
  type: LOAD_WAREHOUSEDATA
});

const success = data => ({
  type: LOAD_WAREHOUSEDATA_SUCCESS,
  payload: {
    ...data
  }
});

const error = data => ({
  type: LOAD_WAREHOUSEDATA_ERROR,
  payload: {
    ...data
  }
});
