import {
  LOAD_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS,
  LOAD_NOTIFICATIONS_SUCCESS,
  LOAD_NOTIFICATIONS_ERROR,
  UPDATE_NOTIFICATIONS_COUNT,
} from "../actions/types";

import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import amber from "@material-ui/core/colors/amber";

export const initialState = {
  loading: true,
  error: false,
  notifications: [],
  emergencyOpenCount: 0,
  emergencyAckCount: 0,
  emergencyRectCount: 0,
  read: 0,
  unread: 0,
};

function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
        emergencyOpenCount: 0,
        emergencyAckCount: 0,
        emergencyRectCount: 0,
        loading: true,
        error: false,
      };
    case UPDATE_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case LOAD_NOTIFICATIONS_SUCCESS:
      // emergency count for topbar alert
      let emergencyOpenCount, emergencyAckCount, emergencyRectCount;
      if (Array.isArray(action.payload.data))
        emergencyOpenCount = action.payload.data.filter(function (value) {
          return value.emergency === true && value.status === "OPEN";
        }).length;
      if (Array.isArray(action.payload.data))
        emergencyAckCount = action.payload.data.filter(function (value) {
          return value.emergency === true && value.status === "ACK";
        }).length;
      if (Array.isArray(action.payload.data))
        emergencyRectCount = action.payload.data.filter(function (value) {
          return value.emergency === true && value.status === "RECT";
        }).length;

      // notifications

      const data = action.payload.data;

      if (Array.isArray(data) && data.length < 1) {
        // console.log("nothing");
        // fix: show nothing
      } else {
        data.forEach((element, key) => {
          // Convert timestamp from UTC to local
          data[key].timestamp = new Date(data[key].timestamp).toLocaleString(
            "en-US",
            {
              month: "short",
              day: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            }
          );
          // Set icons
          if (data[key].type.toLowerCase().includes("emergency")) {
            data[key].icon = "alert";
          } else if (data[key].type.toLowerCase() === "temperature") {
            data[key].icon = "temp";
          } else if (data[key].type.toLowerCase() === "rh") {
            data[key].icon = "rh";
          } else if (data[key].type.toLowerCase().includes("energy")) {
            data[key].icon = "electricity";
          } else if (data[key].type.toLowerCase() === "doors") {
            data[key].icon = "door";
          } else if (data[key].type.toLowerCase() === "power - dg") {
            data[key].icon = "generator";
          } else if (data[key].type.toLowerCase() === "water tank") {
            data[key].icon = "tank";
          } else if (data[key].type.toLowerCase() === "solar") {
            data[key].icon = "solar";
          } else if (data[key].type.toLowerCase() === "fire") {
            data[key].icon = "smoke";
          } else {
            // fix: set default icon
          }

          // Set status color
          if (element.status === "OPEN") data[key].statusColor = red[500];
          else if (element.status === "ACK") data[key].statusColor = amber[700];
          else if (element.status === "RECT")
            data[key].statusColor = green[500];
        });
      }

      return {
        ...state,
        notifications: action.payload.data,
        emergencyOpenCount: emergencyOpenCount,
        emergencyAckCount: emergencyAckCount,
        emergencyRectCount: emergencyRectCount,
        loading: false,
        error: false,
      };

    case UPDATE_NOTIFICATIONS_COUNT:
      return {
        ...state,
        read: action.payload.read,
        unread: action.payload.unread,
      };

    case LOAD_NOTIFICATIONS_ERROR:
      return {
        ...state,
        notifications: [],
        emergencyOpenCount: 0,
        emergencyAckCount: 0,
        emergencyRectCount: 0,
        read: 0,
        unread: 0,
        loading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };
    default:
      return state;
  }
}

export default notificationsReducer;
