import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import axios from "axios";

import AppStartup from "./AppStartup";
import socket from "./helpers/socket";

import Login from "./views/Login";
import SummaryView from "./views/Summary";
import WarehouseView from "./views/Warehouse";
import UnitView from "./views/Unit";
import PowerView from "./views/Power";
import TemperatureMetrics from "./views/Metrics/Temperature";
import RHMetrics from "./views/Metrics/RH";
import PowerMetrics from "./views/Metrics/Power";
import DGPowerMetrics from "./views/Metrics/DGPower";
import SolarPowerMetrics from "./views/Metrics/SolarPower";
import DoorMetrics from "./views/Metrics/Door";
import WaterTankMetrics from "./views/Metrics/WaterTank";
import FireMetrics from "./views/Metrics/Fire";

import Alerts from "./views/Alerts";
import ErrorPage from "./views/404";

// import Clients from "./views/Setup/Clients";

// import VesdaAnalytics from "./views/Metrics/Vesda";
// import AttendanceAnalytics from "./views/Metrics/Attendance";
// import RelativeHumidityAnalytics from "./views/Metrics/RelativeHumidity";
// import AmbientTemperatureAnalytics from "./views/Metrics/AmbientTemperature";
// import TruckTemperatureAnalytics from "./views/Metrics/TruckTemperature";
// import TruckVibrationAnalytics from "./views/Metrics/TruckVibration";
// import TruckAccelerationAnalytics from "./views/Metrics/TruckAcceleration";
// import PowerEBAnalytics from "./views/Metrics/PowerEB";
// import PowerSolarAnalytics from "./views/Metrics/PowerSolar";
// import TruckRunningTimeAnalytics from "./views/Metrics/TruckRunningTime";
// import WaterAvailabilityAnalytics from "./views/Metrics/WaterAvailability";
// import FuelAvailabilityAnalytics from "./views/Metrics/FuelAvailability";

function PrivateRoute({
  component: Component,
  type,
  exact,
  strict,
  path,
  ...rest
}) {
  return (
    <Route
      exact={exact}
      strict={strict}
      path={path}
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <AppStartup>
            <Component {...props} {...rest} />
          </AppStartup>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export const PublicRoute = ({
  component: Component,
  type,
  exact,
  strict,
  path,
  ...rest
}) => {
  if (type === "logout") {
    // Unsubscribe Push Notification
    const pushToken = localStorage.getItem("pushToken");
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const request = {
      subscription_token: { device_key: `${pushToken}` },
      subscription_type: "firebase",
    };

    axios.post(
      `${process.env.REACT_APP_API_URL}/api/vapid/unsubscribe`,
      request,
      {
        headers,
      }
    );

    // Unregister Push Notification
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    });

    // Disconnect Web Socket
    socket.disconnect();

    // Clear local storage
    localStorage.removeItem("pushToken");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("username");

    return <Redirect to="/login" />;
  }
  return localStorage.getItem("token") ? (
    <Redirect to="/" />
  ) : (
    <Route
      exact={exact}
      strict={strict}
      path={path}
      {...rest}
      component={Component}
    />
  );
};

const Routes = () => (
  //   if(this.state.isLoggedIn === true){
  //     return (<Redirect to="/your/redirect/page" />);
  // }else{
  //     return (<div>Login Please</div>);
  // }

  <Switch>
    <PrivateRoute exact path="/" component={SummaryView} title="Summary" />
    <PrivateRoute
      exact
      path="/warehouse/:id"
      component={WarehouseView}
      title="Warehouse"
    />
    <PrivateRoute
      exact
      path="/warehouse/:wid/unit/:uid"
      component={UnitView}
      title="Unit"
    />
    <PrivateRoute
      exact
      path="/metric/temp/:sid"
      component={TemperatureMetrics}
      title="Temperature Metrics"
    />
    <PrivateRoute
      exact
      path="/metric/rh/:sid"
      component={RHMetrics}
      title="RH Metrics"
    />
    <PrivateRoute
      exact
      path="/metric/power/:sid"
      component={PowerMetrics}
      title="Power Metrics"
    />
    <PrivateRoute
      exact
      path="/metric/dg/:sid"
      component={DGPowerMetrics}
      title="DG Metrics"
    />
    <PrivateRoute
      exact
      path="/metric/solar/:sid"
      component={SolarPowerMetrics}
      title="Solar Metrics"
    />
    <PrivateRoute
      exact
      path="/metric/door/:sid"
      component={DoorMetrics}
      title="Door Metrics"
    />
    <PrivateRoute exact path="/power" component={PowerView} title="Power" />
    <PrivateRoute
      exact
      path="/metric/watertank/:sid"
      component={WaterTankMetrics}
      title="Water Tank Metrics"
    />
    <PrivateRoute
      exact
      path="/metric/fire/:sid"
      component={FireMetrics}
      title="Fire Metrics"
    />
    {/* <PrivateRoute
      exact
      path="/warehouse/:wid/unit/:uid/rh"
      component={RelativeHumidityAnalytics}
      title="Analytics"
    />
    <PrivateRoute
      exact
      path="/warehouse/:wid/unit/:uid/vesda"
      component={VesdaAnalytics}
      title="Analytics"
    />
    <PrivateRoute
      exact
      path="/warehouse/:wid/unit/:uid/attendance"
      component={AttendanceAnalytics}
      title="Analytics"
    />
    <PrivateRoute
      exact
      path="/warehouse/:wid/unit/:uid/ambient"
      component={AmbientTemperatureAnalytics}
      title="Analytics"
    />
    <PrivateRoute
      exact
      path="/warehouse/:wid/unit/:uid/trucktemp"
      component={TruckTemperatureAnalytics}
      title="Analytics"
    />
    <PrivateRoute
      exact
      path="/warehouse/:wid/unit/:uid/vibration"
      component={TruckVibrationAnalytics}
      title="Analytics"
    />
    <PrivateRoute
      exact
      path="/warehouse/:wid/unit/:uid/acceleration"
      component={TruckAccelerationAnalytics}
      title="Analytics"
    />
    <PrivateRoute
      exact
      path="/warehouse/:wid/unit/:uid/eb"
      component={PowerEBAnalytics}
      title="Analytics"
    />
    <PrivateRoute
      exact
      path="/warehouse/:wid/unit/:uid/solar"
      component={PowerSolarAnalytics}
      title="Analytics"
    />
    <PrivateRoute
      exact
      path="/warehouse/:wid/unit/:uid/runtime"
      component={TruckRunningTimeAnalytics}
      title="Analytics"
    />
    <PrivateRoute
      exact
      path="/warehouse/:wid/unit/:uid/water"
      component={WaterAvailabilityAnalytics}
      title="Analytics"
    />
    <PrivateRoute
      exact
      path="/warehouse/:wid/unit/:uid/fuel"
      component={FuelAvailabilityAnalytics}
      title="Analytics"
    /> */}
    <PrivateRoute exact path="/alerts" component={Alerts} title="Alerts" />
    {/* <PrivateRoute
      exact
      path="/setup/clients"
      component={Clients}
      title="Clients"
    /> */}
    <PublicRoute path="/login" component={Login} />
    <PublicRoute path="/logout" component={Login} type="logout" />
    <PrivateRoute component={ErrorPage} />
  </Switch>
);

export default Routes;
