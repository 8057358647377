import {
  LOAD_POWER,
  LOAD_POWER_SUCCESS,
  LOAD_POWER_ERROR
} from "../actions/types";

export const initialState = {
  loading: true,
  data: {}
};

function locationsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_POWER:
      return {
        ...state,
        data: {},
        loading: true,
        error: false
      };
    case LOAD_POWER_SUCCESS:
      let dataObj = action.payload.data.overall;
      // For UNIT LEVEL
      // dataObj.locations.map((item, key) => {
      //   if (item.units)
      //     Object.assign(dataObj.locations[key], {
      //       children: item.units
      //     });
      // });
      Object.assign(dataObj, {
        children: dataObj.locations,
        name: "overall"
      });
      return {
        ...state,
        data: dataObj,
        loading: false,
        error: false
      };
    case LOAD_POWER_ERROR:
      return {
        ...state,
        data: {},
        loading: true,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
}

export default locationsReducer;
