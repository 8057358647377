import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Icon from "../../../theme/icon";

import ComponentWrapper from "../../../components/ComponentWrapper";
import StatusCard from "./StatusCard";

// import StatusPopout from "./StatusPopout";

function FacilityStatus(props) {
  const { history, loading, error, errorMessage, data } = props;
  const classes = useStyles();

  // fix: temporary disable
  // const [open, setOpen] = useState(false);
  // const [modalTitle, setModalTitle] = useState(null);
  // const [modalValue, setModalValue] = useState(null);

  // const handleClickOpen = (title, value) => () => {
  //   setOpen(true);
  //   setModalTitle(title);
  //   setModalValue(value);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <>
      {/* <StatusPopout
        open={open}
        handleClose={handleClose}
        modalTitle={modalTitle}
        modalValue={modalValue}
      /> */}
      <ComponentWrapper
        loading={loading}
        error={error}
        errorMessage={errorMessage}
      >
        <div className={classes.root}>
          {data.emergencies && (
            <StatusCard
              title="Emergencies"
              value={data.emergencies.value}
              status={data.emergencies.state}
              icon={
                <Icon
                  icon="alert"
                  color="white"
                  size="50"
                  className={`${
                    data.emergencies.state === "CRITICAL_ACTIVE"
                      ? classes.statusIconAnimate
                      : ""
                  }`}
                />
              }
              // onClick={
              //   data.emergencyState !== "NORMAL"
              //     ? handleClickOpen("Emergencies", data.emergencies)
              //     : void 0
              // }
            />
          )}

          {data.metrics && (
            <StatusCard
              title="Metrics"
              value={data.metrics.value}
              status={data.metrics.state}
              icon={<Icon icon="chart" size="50" color="white" />}
              // onClick={
              //   data.metricState !== "NORMAL"
              //     ? handleClickOpen("Metrics", data.metrics)
              //     : void 0
              // }
            />
          )}

          {data.power && (
            <StatusCard
              title="Power (Kwh)"
              value={data.power.value}
              status={data.power.state}
              icon={<Icon icon="electricity" size="50" color="white" />}
              onClick={() => {
                // history.push("/power");
              }}
              // onClick={
              //   data.powerState !== "NORMAL"
              //     ? handleClickOpen("Power", data.power)
              //     : void 0
              // }
            />
          )}

          {data.attendance && (
            <StatusCard
              title="Attendance"
              value={data.attendance.value}
              status={data.attendance.state}
              icon={<Icon icon="user" size="50" color="white" />}
              // onClick={
              //   data.attendanceState !== "NORMAL"
              //     ? handleClickOpen("Attendance", data.attendance)
              //     : void 0
              // }
            />
          )}
        </div>
      </ComponentWrapper>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: theme.spacing(2),
    boxSizing: "border-box",
    height: "100%",
    overflow: "scroll"
  },
  statusIcon: {
    fontSize: "50px"
  },
  statusIconAnimate: {
    animationDuration: ({ duration }) => `${1}s`,
    animationIterationCount: "infinite",
    animationName: `$fadeAnimation`
  },
  "@keyframes fadeAnimation": {
    from: { opacity: 0 },
    to: { opacity: 1 }
  }
}));

const mapStateToProps = state => {
  return {
    data: state.summary.data,
    loading: state.summary.loading,
    error: state.summary.error,
    errorMessage: state.summary.errorMessage
  };
};

export default withRouter(connect(mapStateToProps, null)(FacilityStatus));
