import React from "react";
import { connect } from "react-redux";
import { Grid, Paper, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import PieChart from "./components/PieChart";
import DoughnutChart from "./components/DoughnutChart";
import BarChart from "./components/BarChart";
import CircularProgress from "@material-ui/core/CircularProgress";

function AlertsChart(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.loading ? (
        <div
          style={{
            boxSizing: "border-box",
            height: "100%",
            padding: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress color="black" />
        </div>
      ) : (
        <Paper className={classes.content}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ paddingTop: 25 }}
          >
            <Grid
              item
              xs={2}
              direction="column"
              alignItems="center"
              container="true"
            >
              <Typography
                component="h5"
                variant="h5"
                gutterBottom
                align="center"
              >
                ALERTS
              </Typography>
              <Box className={classes.alertStatus}>
                <Typography
                  component="h2"
                  variant="h2"
                  className={classes.color}
                >
                  {props.data.interval_alerts}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={2}
              direction="column"
              alignItems="center"
              container="true"
            >
              <Typography
                component="h5"
                variant="h5"
                gutterBottom
                align="center"
              >
                ACKNOWLEDGED
              </Typography>
              <Box className={classes.alertStatus}>
                <Typography
                  component="h2"
                  variant="h2"
                  className={classes.color}
                >
                  {props.data.interval_ack - props.data.interval_rect}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={2}
              direction="column"
              alignItems="center"
              container="true"
            >
              <Typography
                component="h5"
                variant="h5"
                gutterBottom
                align="center"
              >
                RECTIFIED
              </Typography>
              <Box className={classes.alertStatus}>
                <Typography
                  component="h2"
                  variant="h2"
                  className={classes.color}
                >
                  {props.data.interval_rect}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={2}
              direction="column"
              alignItems="center"
              container="true"
            >
              <Typography
                component="h5"
                variant="h5"
                gutterBottom
                align="center"
              >
                OPEN
              </Typography>
              <Box className={classes.alertStatus}>
                <Typography
                  component="h2"
                  variant="h2"
                  className={classes.color}
                >
                  {props.data.interval_nack}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {/* {props.rangeOption === "TODAY" && (
            <Grid
              container
              justify="space-evenly"
              alignItems="center"
              style={{ marginTop: 50 }}
            >
              <Grid item xs={2}>
                <Paper className={classes.overFlow}>
                  <Grid item xs={12}>
                    <div
                      className={classes.title}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        variant="h6"
                        align="center"
                        className={classes.color}
                      >
                        ALERTS - Last 1 Hour
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} style={{ padding: 10 }}>
                    <PieChart value={props.data.alerts_last_1h} type="last1" />
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.overFlow}>
                  <Grid item xs={12}>
                    <Box
                      className={classes.title}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="h6"
                        align="center"
                        className={classes.color}
                      >
                        ALERTS - From 00 Hours
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} style={{ padding: 10 }}>
                    <PieChart value={props.data.alerts_from_0h} type="from00" />
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.overFlow}>
                  <Grid item xs={12}>
                    <Box
                      className={classes.title}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="h6"
                        align="center"
                        className={classes.color}
                      >
                        ALERTS - Before 00 Hours
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} style={{ padding: 10 }}>
                    <PieChart
                      value={props.data.alerts_open_before_0h}
                      type="before00"
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )} */}
          <Grid
            container
            justify="space-around"
            alignItems="center"
            style={{ marginTop: 30 }}
          >
            <Grid item xs={6} style={{ paddingRight: "8px" }}>
              <Paper className={classes.overFlow}>
                <Box
                  className={classes.title}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant="h6"
                    align="center"
                    className={classes.color}
                  >
                    Acknowledgement Status
                  </Typography>
                </Box>
                <Grid item xs={12} style={{ padding: 10 }}>
                  <DoughnutChart
                    done={props.data.interval_ack}
                    undone={props.data.interval_nack}
                    type="ack"
                  />
                </Grid>
                <Grid container>
                  <Grid item xs={6} style={{ padding: "8px" }}>
                    <Paper className={classes.overFlow}>
                      <Grid
                        item
                        xs={12}
                        container="true"
                        justify="center"
                        alignItems="center"
                        className={classes.title}
                      >
                        <Typography
                          variant="h6"
                          align="center"
                          className={classes.color}
                        >
                          Aging - Acknowledged
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <BarChart aging={props.data.interval_ack_aging} />
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={6} style={{ padding: "8px" }}>
                    <Paper className={classes.overFlow}>
                      <Grid
                        item
                        xs={12}
                        container="true"
                        justify="center"
                        alignItems="center"
                        className={classes.title}
                      >
                        <Typography
                          variant="h6"
                          align="center"
                          className={classes.color}
                        >
                          Aging - Not Acknowledged
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <BarChart aging={props.data.interval_nack_aging} />
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: "8px" }}>
              <Paper className={classes.overFlow}>
                <Box
                  className={classes.title}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant="h6"
                    align="center"
                    className={classes.color}
                  >
                    Rectification Status
                  </Typography>
                </Box>
                <Grid item xs={12} style={{ padding: 10 }}>
                  <DoughnutChart
                    done={props.data.interval_rect}
                    undone={props.data.interval_nrect}
                    type="rect"
                  />
                </Grid>
                <Grid container>
                  <Grid item xs={6} style={{ padding: "8px" }}>
                    <Paper className={classes.overFlow}>
                      <Grid
                        item
                        xs={12}
                        container="true"
                        justify="center"
                        alignItems="center"
                        className={classes.title}
                      >
                        <Typography
                          variant="h6"
                          align="center"
                          className={classes.color}
                        >
                          Aging - Rectified
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <BarChart aging={props.data.interval_rect_aging} />
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={6} style={{ padding: "8px" }}>
                    <Paper className={classes.overFlow}>
                      <Grid
                        item
                        xs={12}
                        container="true"
                        justify="center"
                        alignItems="center"
                        className={classes.title}
                      >
                        <Typography
                          variant="h6"
                          align="center"
                          className={classes.color}
                        >
                          Aging - Not Rectified
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <BarChart aging={props.data.interval_nrect_aging} />
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  title: {
    height: 40,
    backgroundColor: "black"
  },
  content: {
    height: "100%",
    padding: "16px",
    marginBottom: "16px"
  },
  overFlow: {
    overflow: "hidden"
  },
  alertStatus: {
    height: 60,
    width: 60,
    backgroundColor: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5
  },
  color: {
    color: theme.palette.white
  }
}));

const mapStateToProps = state => {
  return {
    error: state.alertsChart.error,
    loading: state.alertsChart.loading,
    data: state.alertsChart.data
  };
};

export default connect(mapStateToProps, null)(AlertsChart);
