import axios from "axios";

import {
  LOAD_SUMMARY,
  LOAD_SUMMARY_SUCCESS,
  LOAD_SUMMARY_ERROR
} from "./types";

export const loadSummary = id => {
  return dispatch => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/locations/` + id + "/summary", {
        headers
      })
      .then(({ data }) => {
        const payload = {
          data: {
            emergencies: data.emergencies
              ? {
                  value: data.emergencies.value,
                  state: data.emergencies.state
                }
              : null,
            metrics: data.metrics
              ? {
                  value: data.metrics.value,
                  state: data.metrics.state
                }
              : null,
            power: data.power
              ? {
                  value: data.power.value,
                  state: data.power.state
                }
              : null,
            attendance: data.attendance
              ? {
                  value: data.attendance.value,
                  state: data.attendance.state
                }
              : null
          }
        };
        dispatch(success(payload));
      })
      .catch(err => {
        console.log("ERROR! [loadSummary] " + err.message);
        const payload = {
          errorMessage: err.message
        };
        dispatch(error(payload));
      });
  };
};

const loading = () => ({
  type: LOAD_SUMMARY
});

const success = data => ({
  type: LOAD_SUMMARY_SUCCESS,
  payload: {
    ...data
  }
});

const error = data => ({
  type: LOAD_SUMMARY_ERROR,
  payload: {
    ...data
  }
});
