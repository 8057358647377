import axios from "axios";

import {
  LOAD_UNITDATA,
  LOAD_UNITDATA_SUCCESS,
  LOAD_UNITDATA_ERROR
} from "./types";

export const loadUnitMetrics = (wid, uid) => {
  return dispatch => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/metrics/warehouse/` + wid + "/unit/" + uid, {
        headers
      })
      .then(({ data }) => {
        if (Array.isArray(data.uv_unit_metrics)) {
          if (data.uv_unit_metrics.length === 0) {
            throw Error("Nothing to display");
          }
          const payload = {
            uv_unit_metrics: data.uv_unit_metrics
          };
          dispatch(success(payload));
        } else throw Error("API did not return the expected data");
      })
      .catch(err => {
        console.log("ERROR! [loadUnitMetrics] " + err.message);
        const payload = {
          errorMessage: err.message
        };
        dispatch(error(payload));
      });
  };
};

const loading = () => ({
  type: LOAD_UNITDATA
});

const success = data => ({
  type: LOAD_UNITDATA_SUCCESS,
  payload: {
    ...data
  }
});

const error = data => ({
  type: LOAD_UNITDATA_ERROR,
  payload: {
    ...data
  }
});
