import React, { useEffect, useRef } from "react";
import { Paper, Typography, Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import amber from "@material-ui/core/colors/amber";

import Icon from "../../theme/icon";

import UnitBlock from "./components/UnitBlock";

function UnitMetrics(props) {
  const { data } = props;
  const classes = useStyles();
  const parentRef = useRef(null);
  const itemsRef = useRef([]);

  const handleClick = item => {
    parentRef.current.scrollTo({
      behavior: "smooth",
      top: itemsRef.current[item].offsetTop - 100 // Adjusted with top margin
    });
  };

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, data.length);
  }, [data]);

  return (
    <Paper className={classes.paper}>
      <div className={classes.header}>
        <Typography
          variant="h4"
          color="inherit"
          style={{ fontVariant: "small-caps" }}
        >
          Unit Metrics
        </Typography>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.metricsNav}>
            <Tabs
              variant="scrollable"
              scrollButtons="on"
              className={classes.tabs}
            >
              {data.map((item, key) => {
                let emergencyState = null;
                if (item.critical && item.emergency) {
                  if (
                    item.data.some(i => {
                      return i.State === "CRITICAL_ACTIVE";
                    })
                  ) {
                    emergencyState = "CRITICAL_ACTIVE";
                  } else if (
                    item.data.some(i => {
                      return i.State === "RESOLVED";
                    })
                  ) {
                    emergencyState = "RESOLVED";
                  }
                }
                return (
                  <Tab
                    key={key}
                    label={item.type}
                    icon={
                      <Icon
                        icon={item.icon}
                        size="40"
                        color="white"
                        className={`${
                          emergencyState === "CRITICAL_ACTIVE"
                            ? classes.statusIconAnimate
                            : ""
                        }`}
                      />
                    }
                    className={`${classes.tab} ${
                      item.critical
                        ? item.emergency
                          ? emergencyState === "CRITICAL_ACTIVE"
                            ? classes.reorderEmergencyCritical
                            : classes.reorderEmergencyResolved // critical and emergency but not criticalActive
                          : classes.reorder
                        : ""
                    }`}
                    onClick={() => {
                      handleClick(key);
                    }}
                  />
                );
              })}
            </Tabs>
          </div>
          <div className={classes.content} ref={parentRef}>
            {data.map((item, key) => {
              return (
                <UnitBlock
                  key={key}
                  ref={el => (itemsRef.current[key] = el)}
                  data={item.data}
                  title={item.type}
                  icon={item.icon}
                  critical={item.critical}
                  emergency={item.emergency}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    position: "relative"
  },
  header: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "30px",
    backgroundColor: "black",
    color: "white",
    padding: "10px 0",
    textAlign: "center",
    zIndex: 1
  },
  wrapper: {
    paddingTop: "50px",
    height: "calc(100% - 50px)",
    display: "flex",
    flexDirection: "column"
  },
  container: {
    position: "relative",
    height: "100%"
  },
  metricsNav: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100px",
    display: "flex"
  },
  tabs: {
    backgroundColor: "black",
    color: "white",
    width: "100%",
    alignItems: "center"
  },
  tab: {
    color: "white",
    opacity: 1,
    marginRight: "5px",
    marginLeft: "5px"
    // borderTopRightRadius: "10px",
    // borderTopLeftRadius: "10px"
  },
  reorder: {
    // order: -1,
    backgroundColor: theme.palette.status.OUT_OF_RANGEFaded
  },
  reorderEmergencyCritical: {
    // order: -2,
    backgroundColor: theme.palette.status.OUT_OF_RANGEFaded
  },
  reorderEmergencyResolved: {
    // order: -2,
    backgroundColor: theme.palette.status.RESOLVEDFaded
  },
  statusIconAnimate: {
    animationDuration: ({ duration }) => `${1}s`,
    animationIterationCount: "infinite",
    animationName: `$fadeAnimation`
  },
  "@keyframes fadeAnimation": {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },
  content: {
    marginTop: "100px",
    height: "calc(100% - 100px)",
    overflow: "scroll",
    display: "flex",
    flexDirection: "column"
  }
}));

export default UnitMetrics;
