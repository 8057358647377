import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

function breadcrumbGenerator(list) {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      {list.map(item => {
        if (item.link) {
          return (
            <Link
              style={{ color: "white" }}
              component={RouterLink}
              to={item.link}
            >
              {item.title}
            </Link>
          );
        } else {
          return (
            <Typography style={{ color: "white" }}>{item.title}</Typography>
          );
        }
      })}
    </Breadcrumbs>
  );
}

export default breadcrumbGenerator;
