import socket from "../helpers/socket";

import {
  LOAD_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS,
  LOAD_NOTIFICATIONS_SUCCESS,
  LOAD_NOTIFICATIONS_ERROR,
  UPDATE_NOTIFICATIONS_COUNT,
} from "./types";

export const loadNotifications = () => {
  return (dispatch, getState) => {
    dispatch(loading());

    // Initial notification list
    socket.on("notification_data_initial", (data) => {
      dispatch(loading());
      const payload = {
        data,
      };
      dispatch(success(payload));
    });

    // Update notification list by appending
    socket.on("notification_data_update", (data) => {
      const { notifications } = getState().notifications;
      dispatch(updating());

      // Remove duplicates from previous list when receiving new notifications
      if (data.length > 0) {
        data.forEach((dataItem) => {
          let dupIndex = notifications.findIndex(
            // Compare notification id
            (value) => value.id === dataItem.id
          );
          if (dupIndex >= 0) {
            notifications.splice(dupIndex, 1);
          }
        });
      }
      // Append notifications to array
      notifications.unshift(...data);

      if (notifications.length >= 0) {
        const payload = {
          data: notifications,
        };
        dispatch(success(payload));
      }
    });

    // Update notification count
    socket.on("count", (data) => {
      const payload = {
        read: data.read,
        unread: data.unread,
      };
      dispatch(updateCount(payload));
    });

    // Handle error
    socket.on("connect_timeout", () => {
      dispatch(error("Socket: Connection Timeout"));
    });
    socket.on("connect_error", () => {
      dispatch(error("Socket Connection Error! Reconnecting..."));
    });
    socket.on("error", () => {
      dispatch(error("Socket: Error"));
    });
    socket.on("disconnect", () => {
      dispatch(error("Socket Disconnected! Reconnecting..."));
    });
  };
};

const loading = () => ({
  type: LOAD_NOTIFICATIONS,
});

const updating = (data) => ({
  type: UPDATE_NOTIFICATIONS,
  payload: {
    ...data,
  },
});

const success = (data) => ({
  type: LOAD_NOTIFICATIONS_SUCCESS,
  payload: {
    ...data,
  },
});

const updateCount = (data) => ({
  type: UPDATE_NOTIFICATIONS_COUNT,
  payload: {
    ...data,
  },
});

const error = (errorMessage) => ({
  type: LOAD_NOTIFICATIONS_ERROR,
  payload: {
    errorMessage,
  },
});
