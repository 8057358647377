import axios from "axios";

import { LOAD_POWER, LOAD_POWER_SUCCESS, LOAD_POWER_ERROR } from "./types";

export const loadPower = () => {
  return dispatch => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/powerview`, {
        headers
      })
      .then(({ data }) => {
        const payload = {
          data: data
        };
        dispatch(success(payload));
      })
      .catch(err => {
        console.log("ERROR! [loadPower] " + err.message);
        const payload = {
          errorMessage: err.message
        };
        dispatch(error(payload));
      });
  };
};

const loading = data => ({
  type: LOAD_POWER,
  payload: {
    ...data
  }
});

const success = data => ({
  type: LOAD_POWER_SUCCESS,
  payload: {
    ...data
  }
});

const error = data => ({
  type: LOAD_POWER_ERROR,
  payload: {
    ...data
  }
});
