import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, ButtonGroup, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { makeStyles } from "@material-ui/core/styles";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";

import DashboardLayout from "../../layout/Dashboard";

function AlertsLayout(props) {
  const classes = useStyles();

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  useEffect(() => {
    inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
  }, [props.locationData]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleRangeOption = option => {
    let today = new Date();
    let start = new Date();
    let end = new Date();
    switch (option) {
      case "TODAY":
        start.setHours(0, 0, 0, 0);
        props.handleTimeRange({ start: start, end: end });
        setStartDate(start);
        setEndDate(end);
        break;
      case "YESTERDAY":
        start.setDate(today.getDate() - 1);
        start.setHours(0, 0, 0, 0);
        end.setDate(today.getDate() - 1);
        end.setHours(23, 59, 59, 999);
        props.handleTimeRange({ start: start, end: end });
        setStartDate(start);
        setEndDate(end);
        break;
      case "WEEK":
        start = moment()
          .startOf("week")
          .toDate();
        props.handleTimeRange({ start: start, end: end });
        setStartDate(start);
        setEndDate(end);
        break;
      case "MONTH":
        start = moment()
          .startOf("month")
          .toDate();
        props.handleTimeRange({ start: start, end: end });
        setStartDate(start);
        setEndDate(end);
        break;
      default:
        props.handleTimeRange({ start: null, end: null });
    }
    props.setRangeOption(option);
  };
  const handleStartDateChange = d => {
    props.setRangeOption(null);
    setStartDate(d);
    props.handleTimeRange({ start: d, end: endDate });
  };
  const handleEndDateChange = d => {
    props.setRangeOption(null);
    setEndDate(d);
    props.handleTimeRange({ start: startDate, end: d });
  };

  useEffect(() => {
    handleRangeOption(props.rangeOption);
  }, []);

  // useEffect(() => {
  // handleRangeOption(rangeOption);
  // props.handleTimeRange({ start: startDate, end: endDate });
  // }, [startDate, endDate]);

  // const [dateSelection, setDateSelection] = useState("TODAY");

  // const handleIntervalChange = value => {
  //   setInterval(value);
  // };
  // const onDateRangeChange = value => {
  //   if (value[0] === new Date() && value[1] === new Date()) {
  //     setDateSelection("TODAY");
  //   }
  //   setDateRange(value);
  // };

  return (
    <DashboardLayout title={props.title} documentTitle="Alerts">
      <div className={classes.root}>
        {props.locationError ? (
          <div
            style={{
              boxSizing: "border-box",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            ERROR!!
          </div>
        ) : props.locationIsLoading ? (
          <div
            style={{
              boxSizing: "border-box",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CircularProgress color="black" />
          </div>
        ) : (
          <>
            <Grid container justify="space-between">
              <Grid item>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    ref={inputLabel}
                    htmlFor="warehouse-label-placeholder"
                  >
                    Warehouse
                  </InputLabel>

                  <Select
                    native
                    className={classes.selectEmpty}
                    value={props.selection}
                    onChange={e => props.setSelection(e.target.value)}
                    labelWidth={labelWidth}
                    inputProps={{
                      name: "location",
                      id: "warehouse-label-placeholder"
                    }}
                  >
                    {props.locationData.length > 1 && (
                      <option value={0}>All</option>
                    )}
                    {props.locationData.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <ButtonGroup size="medium">
                  <Button
                    // onClick={setDateRange([new Date(), new Date()])}
                    onClick={() => handleRangeOption("MONTH")}
                    color={props.rangeOption === "MONTH" ? "secondary" : ""}
                  >
                    This month
                  </Button>
                  <Button
                    onClick={() => handleRangeOption("WEEK")}
                    color={props.rangeOption === "WEEK" ? "secondary" : ""}
                  >
                    This week
                  </Button>
                  <Button
                    onClick={() => handleRangeOption("YESTERDAY")}
                    color={props.rangeOption === "YESTERDAY" ? "secondary" : ""}
                  >
                    Yesterday
                  </Button>
                  <Button
                    onClick={() => handleRangeOption("TODAY")}
                    color={props.rangeOption === "TODAY" ? "secondary" : ""}
                  >
                    Today
                  </Button>
                  <Button>
                    <DateTimePicker
                      className={classes.textField}
                      clearable
                      disableFuture
                      ampm={true}
                      value={startDate}
                      onChange={handleStartDateChange}
                      format="DD/MM/YYYY HH:mm a"
                    />
                  </Button>
                  <Button>
                    <DateTimePicker
                      className={classes.textField}
                      clearable
                      disableFuture
                      ampm={true}
                      value={endDate}
                      onChange={handleEndDateChange}
                      format="DD/MM/YYYY HH:mm a"
                    />
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <div className={classes.container}>{props.children}</div>
          </>
        )}
      </div>
    </DashboardLayout>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
    padding: "16px",
    overflowY: "scroll"
  },
  container: {
    // height: "100%",
    marginTop: "16px"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const mapStateToProps = state => {
  return {
    locationData: state.locations.data,
    locationIsLoading: state.locations.loading,
    locationError: state.locations.error
  };
};

export default withRouter(connect(mapStateToProps, null)(AlertsLayout));
