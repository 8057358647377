import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { loadPower } from "../../actions/loadPower";
import "./d3Chart.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import palette from "../../theme/palette";
import CircularProgress from "@material-ui/core/CircularProgress";
import Up from "../../assets/icons/up.svg";
import Down from "../../assets/icons/down.svg";

function App(props) {
  const d3Container = useRef(null);

  useEffect(() => {
    let clear;
    if (d3Container.current === null) {
      props.loadPower();
    }
    clear = setInterval(() => {
      d3.select(d3Container.current)
        .selectAll("svg")
        .remove();
      props.loadPower();
    }, 120000);

    return () => {
      clearInterval(clear);
      // d3.selectAll("svg").remove();
    };
  }, [props.match]);

  useEffect(() => {
    if (!props.loading) {
      var treeData = props.data;

      // Set the dimensions and margins of the diagram
      var wrapperWidth = window.innerWidth;
      var wrapperHeigth = window.innerHeight;
      var margin = { top: 20, right: 90, bottom: 30, left: 120 },
        width = wrapperWidth,
        height = wrapperHeigth;
      // append the svg object to the body of the page
      // appends a 'group' element to 'svg'
      // moves the 'group' element to the top left margin

      var svg = d3
        .select(d3Container.current)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("preserveAspectRatio", "xMidYMid meet")
        .attr("viewBox", `0 0 ` + height / 1.2 + ` ` + width / 2 + ``)
        .classed("svg-content", true)
        .call(
          d3
            .zoom()
            .on("zoom", function() {
              svg.attr("transform", d3.event.transform);
            })
            .scaleExtent([0.5, 2.5])
        )
        .append("g");

      var i = 0,
        duration = 750,
        root;

      // declares a tree layout and assigns the size
      var treemap = d3.tree().size([height, width]);
      // .nodeSize([200]);

      // Assigns parent, children, height, depth
      if (treeData !== {}) {
        root = d3.hierarchy(treeData, function(d) {
          return d.children;
        });
      }

      root.x0 = height / 2;
      root.y0 = 0;

      // Collapse after the second level

      // if (root.children) root.children.forEach(collapse);

      update(root);

      // Collapse the node and all it's children
      function collapse(d) {
        if (d.children) {
          d._children = d.children;
          d._children.forEach(collapse);
          d.children = null;
        }
      }

      function update(source) {
        // Assigns the x and y position for the nodes
        var treeData = treemap(root);

        // Compute the new tree layout.
        var nodes = treeData.descendants(),
          links = treeData.descendants().slice(1);

        // Normalize for fixed-depth.
        nodes.forEach(function(d) {
          d.y = d.depth * 500;
        });

        // ****************** Nodes section ***************************

        // Update the nodes...
        var node = svg.selectAll("g.node").data(nodes, function(d) {
          return d.id || (d.id = ++i);
        });

        // Enter any new modes at the parent's previous position.
        var nodeEnter = node
          .enter()
          .append("g")
          .attr("class", "node")
          .attr("transform", function(d) {
            return "translate(" + source.y0 + "," + source.x0 + ")";
          })
          .on("click", click);

        nodeEnter
          .append("foreignObject")
          .attr("width", 200)
          .attr("height", 200)
          .attr("x", -100)
          .attr("y", -100)
          .attr("overflow", "visible")
          .append("xhtml:div")
          .html(d => {
            return (
              `<div class="d3p-circle" style="background-color: ` +
              (d.data.dayState === "NORMAL"
                ? palette.status.NORMAL
                : palette.status.OUT_OF_RANGE) +
              ` ">
              <div class="d3p-mtdData">
                <span class="d3p-mtdData-tag">MTD</span> ` +
              d.data.power_mtd +
              `
                &nbsp;<span class="d3p-data-unit">kWh</span> <img src="` +
              (d.data.deviationState === "NORMAL" ? Down : Up) +
              `" class="d3p-data-img" /> ` +
              d.data.power_deviation +
              `
                &nbsp;<span class="d3p-data-unit">kWh</span> vs Individual Meters
              </div>
                <div class="d3p-content">
                  <div class="d3p-dayData" style="background-color: ` +
              (d.data.dayState === "NORMAL"
                ? palette.status.NORMALFaded
                : palette.status.OUT_OF_RANGEFaded) +
              ` ">
                    <span class="d3p-dayData-tag">24H</span>
                    <span>` +
              d.data.power_24h +
              `
                      <span class="d3p-data-unit">kWh</span>
                    </span>
                  </div>
                  <div class="d3p-hourData" style="background-color: ` +
              (d.data.hourState === "NORMAL"
                ? palette.status.NORMALFaded
                : palette.status.OUT_OF_RANGEFaded) +
              ` ">
                    <span>` +
              d.data.power_1h +
              `
                      <span class="d3p-data-unit">kWh</span>
                    </span>
                  <span class="d3p-hourData-tag">1H</span>
                  </div>
                  <div class="d3p-title">` +
              d.data.name +
              `</div>
                </div>
              </div>`
            );
          });

        //***************************************************************************//

        // UPDATE
        var nodeUpdate = nodeEnter.merge(node);

        // Transition to the proper position for the node
        // for nodeEnter
        nodeUpdate
          .transition()
          .duration(duration)
          .attr("transform", function(d) {
            return "translate(" + d.y + "," + d.x + ")";
          });

        // Update the node attributes and style
        nodeUpdate
          .select("circle.node")
          .attr("r", 10)
          .style("fill", function(d) {
            return d._children ? "lightsteelblue" : "#fff";
          })
          .attr("cursor", "pointer");

        // Remove any exiting nodes
        var nodeExit = node
          .exit()
          .transition()
          .duration(duration)
          .attr("transform", function(d) {
            return "translate(" + source.y + "," + source.x + ")";
          })
          .remove();

        // On exit reduce the node circles size to 0
        nodeExit.select("circle").attr("r", 1e-6);

        // On exit reduce the opacity of text labels
        nodeExit.select("text").style("fill-opacity", 1e-6);

        // ****************** links section ***************************

        // Update the links...
        var link = svg.selectAll("path.link").data(links, function(d) {
          return d.id;
        });

        // Enter any new links at the parent's previous position.
        var linkEnter = link
          .enter()
          .insert("path", "g")
          .attr("class", "link")
          .attr("d", function(d) {
            var o = { x: source.x0, y: source.y0 };
            return diagonal(o, o);
          });

        // UPDATE
        var linkUpdate = linkEnter.merge(link);

        // Transition back to the parent element position
        linkUpdate
          .transition()
          .duration(duration)
          .attr("d", function(d) {
            return diagonal(d, d.parent);
          });

        // Remove any exiting links
        var linkExit = link
          .exit()
          .transition()
          .duration(duration)
          .attr("d", function(d) {
            var o = { x: source.x, y: source.y };
            return diagonal(o, o);
          })
          .remove();

        // Store the old positions for transition.
        nodes.forEach(function(d) {
          d.x0 = d.x;
          d.y0 = d.y;
        });

        // Creates a curved (diagonal) path from parent to the child nodes
        function diagonal(s, d) {
          var path = `M ${s.y} ${s.x}
           C ${(s.y + d.y) / 2} ${s.x},
             ${(s.y + d.y) / 2} ${d.x},
             ${d.y} ${d.x}`;
          return path;
        }
        // *************************************************************************** //
        // to create lines n marking text
        // line for 24 hours

        // *************************************************************************** //

        // Toggle children on click.
        function click(d) {
          if (d.children) {
            d._children = d.children;
            d.children = null;
          } else {
            d.children = d._children;
            d._children = null;
          }
          update(d);
        }
      }
    }
  }, [props.data]);
  return (
    <div>
      {props.error ? (
        <div
          style={{
            boxSizing: "border-box",
            height: "100%",
            padding: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          ERROR!!
        </div>
      ) : props.loading ? (
        <div
          style={{
            boxSizing: "border-box",
            height: "100%",
            padding: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress color="black" />
        </div>
      ) : (
        <div ref={d3Container} style={{ backgroundColor: "white" }} />
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.power.loading,
    error: state.power.error,
    data: state.power.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadPower: () => {
      dispatch(loadPower());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
