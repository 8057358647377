import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

function ErrorPage() {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" className={classes.root}>
      <Grid container="true" justify="center">
        <Grid item xs={12} container="true" justify="center">
          <Typography className={classes.title}>404</Typography>
        </Grid>
        <Grid
          xs={12}
          container="true"
          justify="center"
          className={classes.space}
        >
          <Typography className={classes.subTitle1}>PAGE NOT FOUND</Typography>
        </Grid>
        <Grid xs={12} container="true" justify="center">
          <Link to="/">
            <Typography className={classes.link}>RETURN TO HOME</Typography>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  title: {
    fontSize: 150,
    fontWeight: 900,
    fontFamily: "Roboto",
    letterSpacing: 10,
    color: "black",
    marginBottom: 75
  },
  subTitle1: {
    fontSize: 15,
    fontFamily: "Roboto",
    fontWeight: 900,
    letterSpacing: 20
  },
  link: {
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: 300,
    letterSpacing: 2,
    textDecoration: "underline",
    color: "blue"
  },
  space: {
    marginBottom: 15
  }
}));

export default ErrorPage;
