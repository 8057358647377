import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fade, makeStyles } from "@material-ui/core/styles";
import {
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import RoomIcon from "@material-ui/icons/Room";
import ForwardIcon from "@material-ui/icons/Forward";
// import WarningIcon from "@material-ui/icons/Warning";

function FacilitiesSearchable(props) {
  const { locationList, selection, handleClick } = props;

  // let redirectAlert = "/alerts";
  const classes = useStyles();
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    setFilteredList(locationList);
  }, [locationList]);

  const handleChange = event => {
    let searchInput = event.target.value;
    let filtered = locationList.filter(facility => {
      return facility.name
        .toLocaleLowerCase()
        .includes(searchInput.toLocaleLowerCase());
    });
    setFilteredList(filtered);
  };

  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
          inputProps={{ "aria-label": "search" }}
          onChange={handleChange}
        />
      </div>
      <div className={classes.facilityList}>
        <List>
          {locationList.length > 1 && (
            <div style={{ display: "flex" }}>
              <ListItem
                button
                selected={selection === 0}
                onClick={() => handleClick(0)}
                style={{ display: "flex" }}
              >
                <ListItemIcon style={{ minWidth: "40px" }}>
                  <RoomIcon />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography color="inherit">All</Typography>
                </ListItemText>
              </ListItem>
              <Divider />
            </div>
          )}

          {locationList ? (
            filteredList.map((facility, key) => {
              let redirect = "/warehouse/" + facility.id;
              return (
                <div
                  style={{
                    display: "flex",
                    width: "100%"
                  }}
                  key={key}
                >
                  <ListItem
                    button
                    selected={selection === facility.id}
                    onClick={() => handleClick(facility.id)}
                  >
                    <ListItemIcon
                      disableTypography
                      style={{ minWidth: "40px" }}
                    >
                      <RoomIcon color="inherit" />
                    </ListItemIcon>
                    <ListItemText disableTypography>
                      <Typography
                        style={{
                          wordBreak: "break-word"
                        }}
                        color="inherit"
                      >
                        {facility.name}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  {/* <ListItem
                        button
                        component={Link}
                        to={redirectAlert}
                        style={{ width: "auto", flex: "1" }}
                      >
                        <ListItemIcon
                          disableTypography
                          style={{ minWidth: "unset" }}
                        >
                          <WarningIcon color="inherit" />
                        </ListItemIcon>
                      </ListItem> */}
                  <ListItem
                    button
                    component={Link}
                    to={redirect}
                    style={{ width: "auto", flex: "1" }}
                  >
                    <ListItemIcon
                      disableTypography
                      style={{ minWidth: "unset" }}
                    >
                      <ForwardIcon color="inherit" />
                    </ListItemIcon>
                  </ListItem>
                </div>
              );
            })
          ) : (
            <Typography>Nothing to display</Typography>
          )}
        </List>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    boxSizing: "border-box",
    height: "100%",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.25)
    }
  },
  searchIcon: {
    width: theme.spacing(8),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    padding: theme.spacing(1, 2, 1, 8),
    width: "100%"
  },
  facilityList: {
    height: "100%"
    // overflowY: "scroll"
  }
  // facilityActive: {
  //   backgroundColor: "#2979ff",
  //   color: "#fff"
  // },
}));

const mapStateToProps = state => {
  return {
    locationList: state.locations.data,
    loading: state.locations.loading,
    error: state.locations.error,
    errorMessage: state.locations.errorMessage
  };
};

export default connect(mapStateToProps, null)(FacilitiesSearchable);
