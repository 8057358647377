import {
  LOAD_ROLEACCESS,
  LOAD_ROLEACCESS_SUCCESS,
  LOAD_ROLEACCESS_ERROR
} from "../actions/types";

export const initialState = {
  loading: true,
  error: false,
  role: null,
  allowAck: false,
  allowRect: false,
  downloadFiles: false,
  readOnly: false
};

function roleAccessReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_ROLEACCESS:
      return {
        ...state,
        loading: true,
        error: false
      };
    case LOAD_ROLEACCESS_SUCCESS:
      const role = action.payload.role;
      let access = {
        allowAck: false,
        allowRect: false,
        downloadFiles: false,
        readOnly: false
      };
      switch (role) {
        case "Superuser":
          access.allowAck = true;
          access.allowRect = true;
          access.downloadFiles = true;
          access.readOnly = false;
          break;
        case "Admin":
          access.allowAck = true;
          access.allowRect = true;
          access.downloadFiles = true;
          access.readOnly = false;
          break;
        case "CXO":
          access.allowAck = true;
          access.allowRect = true;
          access.downloadFiles = true;
          access.readOnly = false;
          break;
        case "Manager":
          access.allowAck = true;
          access.allowRect = true;
          access.downloadFiles = true;
          access.readOnly = false;
          break;
        case "Supervisor":
          access.allowAck = true;
          access.allowRect = false;
          access.downloadFiles = true;
          access.readOnly = false;
          break;
        case "Guest":
          access.allowAck = true;
          access.allowRect = true;
          access.downloadFiles = true;
          access.readOnly = true;
          break;
        default:
      }
      return {
        ...state,
        ...access,
        role: action.payload.role,
        loading: false,
        error: false
      };
    case LOAD_ROLEACCESS_ERROR:
      return {
        ...state,
        role: null,
        loading: true,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
}

export default roleAccessReducer;
