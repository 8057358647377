import {
  LOAD_LOCATIONSTATE,
  LOAD_LOCATIONSTATE_SUCCESS,
  LOAD_LOCATIONSTATE_ERROR
} from "../actions/types";

export const initialState = {
  loading: true,
  data: []
};

function locationStateReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_LOCATIONSTATE:
      return {
        ...state,
        data: [],
        loading: true,
        error: false
      };
    case LOAD_LOCATIONSTATE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false
      };
    case LOAD_LOCATIONSTATE_ERROR:
      return {
        ...state,
        data: [],
        loading: true,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
}

export default locationStateReducer;
