import { createStore, applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";

import rootReducer from "./reducers";

const middlewares = [];

if (process.env.NODE_ENV === `development`) {
  const loggerMiddleware = createLogger();
  middlewares.push(loggerMiddleware);
}

const history = createBrowserHistory();
middlewares.push(routerMiddleware(history));

middlewares.push(thunkMiddleware);

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;
