import io from "socket.io-client";

const token = localStorage.getItem("token");
const socket = io(`${process.env.REACT_APP_API_URL}/notifications`, {
  transports: ["websocket"],
});

// Authenticate on connection
socket.on("connect", () => {
  socket.emit("authenticate", {
    token: token,
  });
});

export default socket;
