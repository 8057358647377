import moment from "moment";
import _ from "lodash";

function getTimeRange(option) {
  let start = moment();
  let end = moment();

  switch (option) {
    case "TODAY":
      start = moment().startOf("day");
      break;
    case "YESTERDAY":
      start = moment()
        .subtract(1, "day")
        .startOf("day");
      end = moment().startOf("day");
      break;
    case "WEEK":
      start = moment().startOf("week");
      break;
    case "MONTH":
      start = moment().startOf("month");
      break;
    default:
  }
  return { start_time: start, end_time: end };
}

function getAllowedIntervals(availableIntervals, start_time, end_time) {
  let diffTime = moment.duration(end_time.diff(start_time));
  let diffHrs = diffTime.asHours();
  let allowedIntervals;
  let defaultInterval;
  if (diffHrs > 168) {
    allowedIntervals = ["1D"];
  } else if (diffHrs <= 168 && diffHrs > 24) {
    allowedIntervals = ["1D", "1H"];
  } else if (diffHrs <= 24) {
    allowedIntervals = ["1H", "30M", "15M"];
  }

  allowedIntervals = _.intersection(availableIntervals, allowedIntervals);
  if (allowedIntervals.includes("15M")) {
    defaultInterval = "15M";
  } else if (allowedIntervals.includes("30M")) {
    defaultInterval = "30M";
  } else if (allowedIntervals.includes("1H")) {
    defaultInterval = "1H";
  } else if (allowedIntervals.includes("1D")) {
    defaultInterval = "1D";
  }

  return { allowedIntervals, defaultInterval };
}

export { getTimeRange, getAllowedIntervals };
