import axios from "axios";

import {
  LOAD_METRICREPORT,
  LOAD_METRICREPORT_SUCCESS,
  LOAD_METRICREPORT_ERROR
} from "./types";

export const loadMetricReport = (id, start_t, end_t, intr, file_type) => {
  return dispatch => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
    const request = {
      start_time: start_t.toDate().toISOString(),
      end_time: end_t.toDate().toISOString(),
      interval: intr
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/report/metric/` + id + "/" + file_type, {
        params: request,
        responseType: "blob",
        headers
      })
      .then(response => {
        const contentDisposition = response.headers["content-disposition"];
        let filename =
          contentDisposition &&
          contentDisposition
            .split(";")[1]
            .split("filename")[1]
            .split("=")[1]
            .trim()
            .replace(/['"]+/g, "");
        const payload = {
          filename: filename,
          file_type: file_type,
          sensorId: id,
          data: response.data
        };
        dispatch(success(payload));
      })
      .catch(err => {
        console.log("ERROR! [loadMetricsReport] " + err.message);
        const payload = {
          errorMessage: err.message
        };
        dispatch(error(payload));
      });
  };
};

const loading = () => ({
  type: LOAD_METRICREPORT
});

const success = data => ({
  type: LOAD_METRICREPORT_SUCCESS,
  payload: {
    ...data
  }
});

const error = data => ({
  type: LOAD_METRICREPORT_ERROR,
  payload: {
    ...data
  }
});
