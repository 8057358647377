import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

function StatusCard({ onClick, ...props }) {
  const { status, icon, value, title } = props;
  const classes = useStyles();

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        m={1}
        onClick={onClick}
        boxShadow={5}
        className={classes.statusCard}
        style={onClick && { cursor: "pointer" }}
      >
        <Box
          bgcolor={`status.${status}`}
          color="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.statusIconContainer}
        >
          {icon}
        </Box>
        <Box
          bgcolor={`status.${status}Faded`}
          display="flex"
          position="relative"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-evenly"
          className={classes.statusContent}
        >
          <Typography
            variant="h1"
            style={{
              color: "white",
              fontSize: "40px",
              flex: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {value}
          </Typography>
          <Box
            style={{
              flex: 1,
              position: "absolute",
              bottom: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Typography variant="h6" style={{ color: "white" }}>
              {title}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  statusCard: {
    borderRadius: "10px"
  },
  statusIconContainer: {
    height: "120px",
    flex: 4,
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px"
  },
  statusContent: {
    height: "120px",
    flex: 7,
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px"
  }
}));

export default StatusCard;
