import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Collapse,
  Divider
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import NfcIcon from "@material-ui/icons/Nfc";
import ApartmentIcon from "@material-ui/icons/Apartment";
import PinDropIcon from "@material-ui/icons/PinDrop";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import MemoryIcon from "@material-ui/icons/Memory";
import BusinessIcon from "@material-ui/icons/Business";
import BookIcon from "@material-ui/icons/Book";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import FlightIcon from "@material-ui/icons/Flight";

import Logo from "../../assets/genesis-logo.png";
import LocationList from "./LocationList";

function SideDrawer(props) {
  const classes = useStyles();

  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);

  const handleClick4 = () => {
    setOpen4(!open4);
  };
  const handleClick5 = () => {
    setOpen5(!open5);
  };

  return (
    <div>
      <Drawer open={props.open} onClose={props.handleToggle(false)}>
        {/* <Box className={classes.root}>
          <Box className={classes.imgWrapper}>
            <img
              src={Logo}
              alt="GENESIS"
              height="25"
              width="200"
              style={{ padding: "15px" }}
            />
          </Box>

          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            <TreeItem nodeId="1" label="Applications" labelIcon={<SupervisorAccountIcon/>}>
              <TreeItem nodeId="2" label="Calendar" />
              <TreeItem nodeId="3" label="Chrome" />
              <TreeItem nodeId="4" label="Webstorm" />
            </TreeItem>
            <TreeItem nodeId="5" label="Documents">
              <TreeItem nodeId="6" label="Material-UI">
                <TreeItem nodeId="7" label="src">
                  <TreeItem nodeId="8" label="index.js" />
                  <TreeItem nodeId="9" label="tree-view.js" />
                </TreeItem>
              </TreeItem>
            </TreeItem>
          </TreeView>
        </Box> */}

        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              <img
                src={Logo}
                alt="GENESIS"
                height="25"
                style={{ padding: "15px" }}
              />
            </ListSubheader>
          }
          className={classes.root}
        >
          <LocationList />
          <Divider />
          <ListItem button component={Link} to="/alerts">
            <ListItemText primary="Alerts" />
          </ListItem>
          <Divider />
          <ListItem button onClick={handleClick4}>
            <ListItemText primary="Operational Setup" />
            {open4 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open4} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <AssignmentIndIcon />
                </ListItemIcon>
                <ListItemText primary="Client" />
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <NfcIcon />
                </ListItemIcon>
                <ListItemText primary="Sensor Type" />
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <MeetingRoomIcon />
                </ListItemIcon>
                <ListItemText primary="Room Type" />
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <PinDropIcon />
                </ListItemIcon>
                <ListItemText primary="Region" />
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <GpsFixedIcon />
                </ListItemIcon>
                <ListItemText primary="Location" />
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <ApartmentIcon />
                </ListItemIcon>
                <ListItemText primary="Building" />
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                <ListItemText primary="Floor" />
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText primary="Unit" />
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <MemoryIcon />
                </ListItemIcon>
                <ListItemText primary="Sensor" />
              </ListItem>
            </List>
          </Collapse>
          <Divider />
          <ListItem button onClick={handleClick5}>
            <ListItemText primary="User Setup" />
            {open5 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open5} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <PeopleAltIcon />
                </ListItemIcon>
                <ListItemText primary="User" />
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <EventAvailableIcon />
                </ListItemIcon>
                <ListItemText primary="Shift" />
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <FlightIcon />
                </ListItemIcon>
                <ListItemText primary="Holiday" />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Drawer>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    // backgroundColor: "black",
    padding: 10,
    width: 250
    // color: "white",
  },
  imgWrapper: {
    display: "flex",
    justifyContent: "center"
  }
});

export default SideDrawer;
