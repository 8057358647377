import React, { useState } from "react";
import { connect } from "react-redux";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/styles";
import red from "@material-ui/core/colors/red";
import amber from "@material-ui/core/colors/amber";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import Icon from "../theme/icon";

import AckPopup from "../views/Alerts/AlertsAction/AckPopup";
import RectPopup from "../views/Alerts/AlertsAction/RectPopup";

function NotificationList(props) {
  const classes = useStyles();
  const [isAckOpen, setAckOpen] = useState(false);
  const [isRectOpen, setRectOpen] = useState(false);

  const [id, setID] = useState(null);

  const handleAck = (id) => {
    setID(id);
    setAckOpen(true);
  };

  const handleRect = (id, action = null) => {
    setID(id);
    setRectOpen(true);
  };

  const handleClose = () => {
    setAckOpen(false);
    setRectOpen(false);
  };

  return (
    <>
      <AckPopup open={isAckOpen} handleClose={handleClose} id={id} />
      <RectPopup open={isRectOpen} handleClose={handleClose} id={id} />
      <List disablePadding className={classes.list}>
        {props.error ? (
          <div
            style={{
              boxSizing: "border-box",
              height: "100%",
              padding: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>ERROR!!</Typography>
          </div>
        ) : props.loading ? (
          <div
            style={{
              boxSizing: "border-box",
              height: "100%",
              padding: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="black" />
          </div>
        ) : props.notifications.length > 0 ? (
          props.notifications.slice(0, props.count).map((notification, i) => (
            <ListItem
              divider={i < props.notifications.length - 1}
              key={i}
              className={`${
                notification.emergency
                  ? notification.status === "RECT"
                    ? classes.emergencyRectItem
                    : classes.emergencyItem
                  : ""
              }`}
              style={{
                order: notification.emergency
                  ? notification.status === "RECT"
                    ? -1
                    : -2
                  : 1,
              }}
            >
              <ListItemAvatar>
                {/* <i
                  className={`icon-sm ${notification.icon} ${
                    notification.emergency ? classes.emergencyIcon : ""
                  }`}
                /> */}
                {/* fix: temporary */}
                <Icon
                  icon={notification.icon}
                  size="30"
                  color={`${notification.emergency ? "white" : "black"}`}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    style={{ overflowWrap: "break-word" }}
                    className={`${
                      notification.emergency ? classes.emergencyText : ""
                    }`}
                  >
                    {notification.description}
                  </Typography>
                }
                primaryTypographyProps={{ variant: "body1" }}
                secondary={
                  <div
                    className={`${
                      notification.emergency ? classes.emergencyText : ""
                    }`}
                  >
                    <div>{notification.timestamp}</div>
                    {notification.alert_category === "ACTION" && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.notificationStatus}
                          style={{
                            backgroundColor: `${
                              notification.status === "OPEN"
                                ? notification.statusColor
                                : "black"
                            }`,
                            opacity: `${
                              notification.status === "OPEN" ? "1" : ".1"
                            }`,
                          }}
                        >
                          OPEN
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.notificationStatus}
                          style={{
                            backgroundColor: `${
                              notification.status === "ACK"
                                ? notification.statusColor
                                : "black"
                            }`,
                            opacity: `${
                              notification.status === "ACK" ? "1" : ".1"
                            }`,
                          }}
                        >
                          ACK
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.notificationStatus}
                          style={{
                            backgroundColor: `${
                              notification.status === "RECT"
                                ? notification.statusColor
                                : "black"
                            }`,
                            opacity: `${
                              notification.status === "RECT" ? "1" : ".1"
                            }`,
                          }}
                        >
                          RECT
                        </Typography>
                      </div>
                    )}
                  </div>
                }
              />

              {notification.alert_category === "ACTION" ? (
                (notification.status === "OPEN" && props.accessAllowAck && (
                  <Button
                    size="small"
                    variant="outlined"
                    className={`${classes.button} ${
                      notification.emergency ? classes.emergencyText : ""
                    }`}
                    disabled={props.accessReadOnly}
                    onClick={() => {
                      !props.accessReadOnly && handleAck(notification.id);
                    }}
                  >
                    ACK
                  </Button>
                )) ||
                (notification.status === "ACK" && props.accessAllowRect && (
                  <Button
                    size="small"
                    variant="outlined"
                    className={`${classes.button} ${
                      notification.emergency ? classes.emergencyText : ""
                    }`}
                    disabled={props.accessReadOnly}
                    onClick={() => {
                      !props.accessReadOnly && handleRect(notification.id);
                    }}
                  >
                    RECT
                  </Button>
                )) || (
                  <Button
                    className={classes.button}
                    style={{ visibility: "hidden" }}
                  ></Button>
                )
              ) : (
                <>
                  <Button className={classes.button} disabled>
                    <InfoOutlinedIcon />
                  </Button>
                </>
              )}
            </ListItem>
          ))
        ) : (
          <div
            style={{
              boxSizing: "border-box",
              height: "100%",
              padding: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>Nothing to show!!</Typography>
          </div>
        )}
      </List>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    flexDirection: "column",
  },
  emergencyItem: {
    backgroundColor: red[900],
    color: "#fff",
  },
  emergencyRectItem: {
    backgroundColor: amber[900],
    color: "#fff",
  },
  emergencyIcon: {
    backgroundColor: "#fff",
  },
  emergencyText: {
    color: "#fff",
  },
  notificationStatus: {
    color: "#fff",
    paddingTop: "2px",
    paddingBottom: "2px",
    paddingLeft: "5px",
    paddingRight: "5px",
    borderRadius: "5px",
  },
  button: {
    marginLeft: "10px",
  },
}));

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications.notifications,
    loading: state.notifications.loading,
    error: state.notifications.error,
    errorMessage: state.notifications.errorMessage,
    // access
    accessAllowAck: state.roleAccess.allowAck,
    accessAllowRect: state.roleAccess.allowRect,
    accessReadOnly: state.roleAccess.readOnly,
  };
};

export default connect(mapStateToProps)(NotificationList);
