import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";

const Component = props => {
  const [label, setLabel] = useState([]);
  const [color, setColor] = useState([]);

  useEffect(() => {
    if (props.type === "ack") {
      setLabel(["Acknowledged", "Not Acknowledged"]);
      setColor(["#E6AF3F", "#E63631"]);
    } else if (props.type === "rect") {
      setLabel(["Rectified", "Not Rectified"]);
      setColor(["#59C268", "#E63631"]);
    }
  }, [props.type]);

  const data = {
    labels: label,
    datasets: [
      {
        data: [props.done, props.undone],
        backgroundColor: color,
        hoverBackgroundColor: color
      }
    ]
  };
  const options = {
    legend: {
      display: true,
      position: "bottom"
    },
    plugins: {
      datalabels: {
        color: "#ffffff"
      }
    }
  };

  return (
    <div>
      <Doughnut data={data} options={options} width={50} height={20} />
    </div>
  );
};
export default Component;
