import * as firebase from "firebase/app";
import "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyDlEwCkChsUNyyhqltUv54oXtvmLralwcQ",
  projectId: "genesis-ae1e9",
  messagingSenderId: "150379425746",
  appId: "1:150379425746:web:16249b0f0ff03822fba20b",
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();
export { messaging };
