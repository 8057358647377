export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_ERROR = "AUTH_LOGIN_ERROR";

export const LOAD_ROLEACCESS = "LOAD_ROLEACCESS";
export const LOAD_ROLEACCESS_SUCCESS = "LOAD_ROLEACCESS_SUCCESS";
export const LOAD_ROLEACCESS_ERROR = "LOAD_ROLEACCESS_ERROR";

export const LOAD_LOCATIONS = "LOAD_LOCATIONS";
export const LOAD_LOCATIONS_SUCCESS = "LOAD_LOCATIONS_SUCCESS";
export const LOAD_LOCATIONS_ERROR = "LOAD_LOCATIONS_ERROR";

export const LOAD_LOCATIONSTATE = "LOAD_LOCATIONSTATE";
export const LOAD_LOCATIONSTATE_SUCCESS = "LOAD_LOCATIONSTATE_SUCCESS";
export const LOAD_LOCATIONSTATE_ERROR = "LOAD_LOCATIONSTATE_ERROR";

export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const LOAD_NOTIFICATIONS_SUCCESS = "LOAD_NOTIFICATIONS_SUCCESS";
export const LOAD_NOTIFICATIONS_ERROR = "LOAD_NOTIFICATIONS_ERROR";

export const LOAD_NOTIFICATIONS_COUNT = "LOAD_NOTIFICATIONS_COUNT";
export const LOAD_NOTIFICATIONS_COUNT_SUCCESS =
  "LOAD_NOTIFICATIONS_COUNT_SUCCESS";
export const LOAD_NOTIFICATIONS_COUNT_ERROR = "LOAD_NOTIFICATIONS_COUNT_ERROR";
export const UPDATE_NOTIFICATIONS_COUNT = "UPDATE_NOTIFICATIONS_COUNT";

export const LOAD_SUMMARY = "LOAD_SUMMARY";
export const LOAD_SUMMARY_SUCCESS = "LOAD_SUMMARY_SUCCESS";
export const LOAD_SUMMARY_ERROR = "LOAD_SUMMARY_ERROR";

export const LOAD_WAREHOUSEDATA = "LOAD_WAREHOUSEDATA";
export const LOAD_WAREHOUSEDATA_SUCCESS = "LOAD_WAREHOUSEDATA_SUCCESS";
export const LOAD_WAREHOUSEDATA_ERROR = "LOAD_WAREHOUSEDATA_ERROR";

export const LOAD_UNITDATA = "LOAD_UNITDATA";
export const LOAD_UNITDATA_SUCCESS = "LOAD_UNITDATA_SUCCESS";
export const LOAD_UNITDATA_ERROR = "LOAD_UNITDATA_ERROR";

export const LOAD_POWER = "LOAD_POWER";
export const LOAD_POWER_SUCCESS = "LOAD_POWER_SUCCESS";
export const LOAD_POWER_ERROR = "LOAD_POWER_ERROR";

export const LOAD_METRICCHART = "LOAD_METRICCHART";
export const LOAD_METRICCHART_SUCCESS = "LOAD_METRICCHART_SUCCESS";
export const LOAD_METRICCHART_ERROR = "LOAD_METRICCHART_ERROR";

export const LOAD_METRICREPORT = "LOAD_METRICREPORT";
export const LOAD_METRICREPORT_SUCCESS = "LOAD_METRICREPORT_SUCCESS";
export const LOAD_METRICREPORT_ERROR = "LOAD_METRICREPORT_ERROR";

export const LOAD_ALERTSTABLE = "LOAD_ALERTSTABLE";
export const LOAD_ALERTSTABLE_SUCCESS = "LOAD_ALERTSTABLE_SUCCESS";
export const LOAD_ALERTSTABLE_ERROR = "LOAD_ALERTSTABLE_ERROR";

export const LOAD_ALERTSCHART = "LOAD_ALERTSCHART";
export const LOAD_ALERTSCHART_SUCCESS = "LOAD_ALERTSCHART_SUCCESS";
export const LOAD_ALERTSCHART_ERROR = "LOAD_ALERTSCHART_ERROR";

export const LOAD_ALERTS_RECT_ACTION = "LOAD_ALERTS_RECT_ACTION";
export const LOAD_ALERTS_RECT_ACTION_SUCCESS =
  "LOAD_ALERTS_RECT_ACTION_SUCCESS";
export const LOAD_ALERTS_RECT_ACTION_ERROR = "LOAD_ALERTS_RECT_ACTION_ERROR";

export const UPDATE_ALERT_STATE = "UPDATE_ALERT_STATE";
export const UPDATE_ALERT_STATE_SUCCESS = "UPDATE_ALERT_STATE_SUCCESS";
export const UPDATE_ALERT_STATE_ERROR = "UPDATE_ALERT_STATE_ERROR";

export const UPDATE_ALERT_ETA_OR_REASON = "UPDATE_ALERT_ETA_OR_REASON";
export const UPDATE_ALERT_ETA_OR_REASON_SUCCESS =
  "UPDATE_ALERT_ETA_OR_REASON_SUCCESS";
export const UPDATE_ALERT_ETA_OR_REASON_ERROR =
  "UPDATE_ALERT_ETA_OR_REASON_ERROR";

export const UPDATE_ALERT_STATE_ETA_OR_REASON =
  "UPDATE_ALERT_STATE_ETA_OR_REASON";
export const UPDATE_ALERT_STATE_ETA_OR_REASON_SUCCESS =
  "UPDATE_ALERT_STATE_ETA_OR_REASON_SUCCESS";
export const UPDATE_ALERT_STATE_ETA_OR_REASON_ERROR =
  "UPDATE_ALERT_STATE_ETA_OR_REASON_ERROR";
