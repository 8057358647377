import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Paper,
  ButtonGroup,
  Button,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DateTimePicker } from "@material-ui/pickers";
import breadcrumbGenerator from "../../helpers/breadcrumbGenerator";

import DashboardLayout from "../../layout/Dashboard";

import "./daterangepicker-custom.css";

function ChartLayout(props) {
  const classes = useStyles();
  const [title, setTitle] = useState(null);

  useEffect(() => {
    // Set Page Title
    let bc;
    if (props.is_warehouse_level_unit) {
      let warehouseRedirect = "/warehouse/" + props.location_id;

      bc = breadcrumbGenerator([
        { title: props.location_name, link: warehouseRedirect },
        { title: props.metric_name }
      ]);
    } else if (props.location_id && props.unit_id) {
      let warehouseRedirect = "/warehouse/" + props.location_id;
      let unitRedirect =
        "/warehouse/" + props.location_id + "/unit/" + props.unit_id;

      bc = breadcrumbGenerator([
        { title: props.location_name, link: warehouseRedirect },
        { title: props.unit_name, link: unitRedirect },
        { title: props.metric_name }
      ]);
    } else {
      bc = breadcrumbGenerator([{ title: `Metric ${props.match.params.sid}` }]);
    }
    setTitle(bc);
    return () => {
      setTitle(null);
    };
  }, [props.location_id, props.unit_id]);

  return (
    <DashboardLayout title={title} documentTitle="Analytics">
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <Typography component="h2" gutterBottom variant="overline">
              Analytics
            </Typography>
            <Typography component="h1" gutterBottom variant="h3">
              {props.metrics}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.chartContainer}>
          <Grid container justify="space-between">
            <Grid item>
              <ButtonGroup size="medium">
                {props.availableIntervals.includes("1D") && (
                  <Button
                    onClick={() => props.handleDataInterval("1D")}
                    color={props.dataInterval === "1D" ? "secondary" : ""}
                    disabled={
                      props.allowedIntervals.includes("1D") ? false : true
                    }
                  >
                    day
                  </Button>
                )}
                {props.availableIntervals.includes("1H") && (
                  <Button
                    onClick={() => props.handleDataInterval("1H")}
                    color={props.dataInterval === "1H" ? "secondary" : ""}
                    disabled={
                      props.allowedIntervals.includes("1H") ? false : true
                    }
                  >
                    1 hr
                  </Button>
                )}
                {props.availableIntervals.includes("30M") && (
                  <Button
                    onClick={() => props.handleDataInterval("30M")}
                    color={props.dataInterval === "30M" ? "secondary" : ""}
                    disabled={
                      props.allowedIntervals.includes("30M") ? false : true
                    }
                  >
                    30 min
                  </Button>
                )}
                {props.availableIntervals.includes("15M") && (
                  <Button
                    onClick={() => props.handleDataInterval("15M")}
                    color={props.dataInterval === "15M" ? "secondary" : ""}
                    disabled={
                      props.allowedIntervals.includes("15M") ? false : true
                    }
                  >
                    15 min
                  </Button>
                )}
              </ButtonGroup>
            </Grid>
            <Grid item>
              <ButtonGroup size="medium">
                <Button
                  onClick={() => props.handleTimeRangeOption("MONTH")}
                  color={props.timeRangeOption === "MONTH" ? "secondary" : ""}
                >
                  This month
                </Button>
                <Button
                  onClick={() => props.handleTimeRangeOption("WEEK")}
                  color={props.timeRangeOption === "WEEK" ? "secondary" : ""}
                >
                  This week
                </Button>
                <Button
                  onClick={() => props.handleTimeRangeOption("YESTERDAY")}
                  color={
                    props.timeRangeOption === "YESTERDAY" ? "secondary" : ""
                  }
                >
                  Yesterday
                </Button>
                <Button
                  onClick={() => props.handleTimeRangeOption("TODAY")}
                  color={props.timeRangeOption === "TODAY" ? "secondary" : ""}
                >
                  Today
                </Button>
                <Button>
                  <DateTimePicker
                    className={classes.textField}
                    clearable
                    disableFuture
                    ampm={true}
                    value={props.startDate}
                    onChange={props.handleStartDateChange}
                    format="DD/MM/YYYY h:mm a"
                  />
                </Button>
                <Button>
                  <DateTimePicker
                    className={classes.textField}
                    clearable
                    disableFuture
                    ampm={true}
                    value={props.endDate}
                    onChange={props.handleEndDateChange}
                    format="DD/MM/YYYY h:mm a"
                  />
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <div className={classes.chart}>{props.children}</div>
        </div>
      </Paper>
    </DashboardLayout>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    height: "100%",
    overflowY: "scroll",
    flexGrow: 1,
    padding: "16px",
    margin: "16px"
  },
  chartContainer: {
    padding: "16px"
  },
  chart: {
    paddingTop: "16px",
    paddingBottom: "16px",
    height: "370px" //fix: temporary
  },
  textField: {
    width: 160
  }
}));

export default withRouter(ChartLayout);
