import React from "react";
import PropTypes from "prop-types";
import iconPaths from "../assets/icons/selection.json";

function getPath(iconName) {
  const icon = iconPaths.icons.find(icon => icon.properties.name === iconName);

  if (icon) {
    return icon.icon.paths.join(" ");
  } else {
    console.warn(`icon ${iconName} does not exist.`);
    return "";
  }
}

const Icon = ({ color, size, icon, ...rest }) => {
  const styles = {
    svg: {
      display: "inline-block",
      verticalAlign: "middle"
    },
    path: {
      fill: color
    }
  };

  return (
    <i {...rest}>
      <svg
        style={styles.svg}
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 1024 1024"
      >
        <path style={styles.path} d={getPath(icon)}></path>
      </svg>
    </i>
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string
};

Icon.defaultProps = {
  size: 10
};

export default Icon;
