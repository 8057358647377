export default {
  // Temp & RH
  "Temperature & RH": "temprh",
  Temperature: "temp",
  RH: "rh",
  // Power - EM
  "Power - EM": "electricity",
  "EM - MTD": "emmonth",
  "EM - Last 24 hours": "emday",
  "EM - Last 1 hour": "emhour",
  "EM - PF": "ebfactor",
  "EM - Instantaneous": "eminsta",
  "EM Main - MTD": "emmonth",
  "EM Main - Last 24 hours": "emday",
  "EM Main - Last 1 hour": "emhour",
  "EM Main - Instantaneous": "eminsta",
  "EM - Sum of Units - MTD": "ebsum",
  "EM - Sum of Units - Last 24 hours": "ebsum",
  "EM - Sum of Units - Last 1 hour": "ebsum",
  // Power - DG
  "Power - DG": "generator",
  "Run Hours": "icon_dgruntime",
  Mode: "genstatus",
  "Operating State": "icon_dgmode",
  "DG Run Hours - MTD": "dgmonth",
  "DG Run Hours - Last 24 hours": "dgday",
  "DG Run Hours - Last 1 hour": "dghour",
  "DG Run Hours - Instantaneous": "dginsta",
  // Power - Solar
  "Power - Solar": "solar",
  Solar: "solar",
  "Solar Running Status": "solarstatus",
  "Solar Energy Total - MTD": "solarmonth",
  "Solar Energy Total - Last 24 hours": "solarday",
  "Solar Energy Total - Last 1 hour": "solarhour",
  "Solar Energy Total - Instantaneous": "solarinsta",
  // Doors
  Doors: "door",
  "Material - Summary": "material",
  "Man - Summary": "man",
  "Main - Summary": "main",
  "Material - Off hours": "materialoff",
  "Man - Off hours": "manoff",
  "Main - Off hours": "mainoff",
  // Water Tank
  "Water tank": "tank",

  Emergency: "alert",
  "Emergency Button": "alert",
  "Emergency Stop": "emergency",

  "VESDA Faults": "vesda",
  "Reach Truck": "truck",
  Metrics: "chart",
  Attendance: "user",
  Fire: "smoke",
  "VESDA Alert": "smoke1",
  "VESDA Action": "smoke2",
  "VESDA Fire1": "fire1",
  "VESDA Fire2": "fire2",
  "VESDA FlowFaultLow": "airflowlow",
  "VESDA FlowFaultHigh": "airflowhigh",
  "VESDA FilterClogging": "filter",
  "VESDA NetworkFault": "network",
  "VESDA AspiratorSpeed": "aspirator",
};
