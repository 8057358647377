import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Map, TileLayer, Marker, Tooltip } from "react-leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon, latLngBounds } from "leaflet";

import "leaflet/dist/leaflet.css";

import ComponentWrapper from "../../../components/ComponentWrapper";

function MapWithMarkers(props) {
  const { loading, error, errorMessage, facilities, handleClick } = props;
  const [bounds, setBounds] = useState(null);
  const [center, setCenter] = useState([0, 0]);

  const greenMarkerIcon = divIcon({
    html: renderToStaticMarkup(
      <i
        className="icon-sm warehouseIcon"
        style={{ backgroundColor: "green", fontSize: "45px" }}
      />
    ),
  });
  // const greenMarkerIcon = () => {
  //   return (<><i className="icon-md warehouseIcon" /></>)
  // };
  // const redMarkerIcon = () => {
  //   return (<><i className="icon-md warehouseIcon" /></>)
  // };
  const redMarkerIcon = divIcon({
    html: renderToStaticMarkup(
      <i
        className="icon-sm warehouseIcon"
        style={{ backgroundColor: "#ef4b4b", fontSize: "45px" }}
      />
    ),
  });
  const grayMarkerIcon = divIcon({
    html: renderToStaticMarkup(
      <i
        className="icon-sm warehouseIcon"
        style={{ backgroundColor: "#606060", fontSize: "45px" }}
      />
    ),
  });

  useEffect(() => {
    if (facilities.length > 1) {
      let plotValue = facilities.map((item) => {
        return { lat: item.latitude, lng: item.longitude };
      });
      plotValue && setBounds(latLngBounds(plotValue));
    } else if (facilities.length === 1) {
      let plotValue = [facilities[0].latitude, facilities[0].longitude];
      setCenter(plotValue);
    }
  }, [facilities]);

  return (
    <>
      <ComponentWrapper
        loading={loading}
        error={error}
        errorMessage={errorMessage}
      >
        <Map
          zoom="9"
          center={center}
          bounds={bounds}
          boundsOptions={{ padding: [80, 80] }}
          style={{ zIndex: 20 }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
          />
          {facilities.map((facility, key) => (
            <Marker
              position={{ lat: facility.latitude, lng: facility.longitude }}
              key={key}
              onClick={() => handleClick(facility.id)}
              icon={
                facility.state === "NORMAL"
                  ? greenMarkerIcon
                  : facility.state === "OUT_OF_RANGE"
                  ? redMarkerIcon
                  : grayMarkerIcon
              }
            >
              <Tooltip>{facility.name}</Tooltip>
            </Marker>
          ))}
        </Map>
      </ComponentWrapper>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    facilities: state.locationState.data,
    loading: state.locationState.loading,
    error: state.locationState.error,
    errorMessage: state.locationState.errorMessage,
  };
};

export default connect(mapStateToProps, null)(MapWithMarkers);
