import _ from "lodash";

import iconMap from "../assets/IconMap.js";
import {
  LOAD_WAREHOUSEDATA,
  LOAD_WAREHOUSEDATA_SUCCESS,
  LOAD_WAREHOUSEDATA_ERROR,
} from "../actions/types";

export const initialState = {
  loading: true,
  error: false,
  unitSummary: [],
  warehouseMetrics: [],
};

function warehouseReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_WAREHOUSEDATA:
      return {
        ...state,
        unitSummary: [],
        warehouseMetrics: [],
        loading: true,
        error: false,
      };
    case LOAD_WAREHOUSEDATA_SUCCESS:
      const wvdata = action.payload.wv_warehouse_metrics;

      wvdata.map((item, key) => {
        // Set icon for all items
        wvdata[key].Icon = iconMap[item["Metric Sub-Type"]];

        // Assign Door Card values
        if (
          item["Metric Sub-Type"] === "Material - Summary" ||
          item["Metric Sub-Type"] === "Man - Summary" ||
          item["Metric Sub-Type"] === "Main - Summary"
        ) {
          if (
            item["Value Duration Minutes"] !== null &&
            item["Threshold crosses"] !== null
          ) {
            wvdata[key].fontSize = 20;
            wvdata[key].Value =
              item.Value +
              " | " +
              item["Value Duration Minutes"] +
              " | " +
              item["Threshold crosses"];
          }
        }
        if (item["Metric Sub-Type"] === "Operating State")
          wvdata[key].fontSize = "100%";
      });

      // Grouping based on Metric Type
      var groupedObj = _.chain(wvdata)
        .groupBy("Metric Type")
        .map((item, key) => {
          // Set icon for all items
          let icon = null;
          icon = iconMap[key];

          // Block level ordering based on state
          let order;
          if (
            item.some((i) => {
              return (
                i.State === "OUT_OF_RANGE" || i.State === "CRITICAL_ACTIVE"
              );
            })
          )
            if (key === "Emergency") order = -1;
            else order = 1;
          else if (
            item.some((i) => {
              return i.State === "RESOLVED";
            })
          )
            if (key === "Emergency") order = 0;
            else order = 2;
          else order = 3;

          item.map((subItem, key1) => {
            // Setting order based on the state
            if (
              subItem.State === "OUT_OF_RANGE" ||
              subItem.State === "CRITICAL_ACTIVE"
            )
              item[key1].order = 1;
            else if (subItem.State === "RESOLVED") item[key1].order = 2;
            else item[key1].order = 3;
          });

          // Check for critical
          let criticalBlock = item.some((i) => {
            return (
              i.State === "OUT_OF_RANGE" ||
              i.State === "CRITICAL_ACTIVE" ||
              i.State === "RESOLVED"
            );
          });

          return {
            icon: icon,
            type: key,
            critical: criticalBlock,
            // emergency: emergencyBlock,
            order: order,
            data: item,
          };
        })
        .value();

      groupedObj.map((item, key) => {
        // Sorting based on order(state)
        item.data = _.sortBy(item.data, "order");
        // Grouping and spreading based on Sensor Name
        let namedGroup = _.groupBy(item.data, "Sensor Name");
        let orderedList = [];
        Object.values(namedGroup).map((i) => orderedList.push(...i));
        groupedObj[key].data = orderedList;
      });

      groupedObj = _.sortBy(groupedObj, "order");

      return {
        ...state,
        unitSummary: action.payload.wv_unit_summary,
        warehouseMetrics: groupedObj,
        loading: false,
        error: false,
      };
    case LOAD_WAREHOUSEDATA_ERROR:
      return {
        ...state,
        unitSummary: [],
        warehouseMetrics: [],
        loading: true,
        error: true,
        errorMessage: action.payload.errorMessage,
      };
    default:
      return state;
  }
}

export default warehouseReducer;
