import { colors } from "@material-ui/core";

const white = "#FFFFFF";
const black = "#000000";

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.indigo[900],
    main: black,
    light: colors.indigo[100]
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue["A400"],
    light: colors.blue["A400"]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  status: {
    NORMAL: "#3A8447",
    NORMALFaded: "#59C268",
    OUT_OF_RANGE: "#A2231F",
    OUT_OF_RANGEFaded: "#E63631",
    CRITICAL_ACTIVE: "#A2231F",
    CRITICAL_ACTIVEFaded: "#E63631",
    RESOLVED: "#B4892F",
    RESOLVEDFaded: "#E6AF3F",
    INACTIVE: "#7D7D7D",
    INACTIVEFaded: "#A6A6A6",
    UNSET: "#7D7D7D",
    UNSETFaded: "#A6A6A6"
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: "#F4F6F8",
    paper: white
  },
  divider: colors.grey[200]
};
