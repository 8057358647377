import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { Grid, Typography, Divider } from "@material-ui/core";

import {
  updateAlertState,
  updateStateEtaOrAction
} from "../../../actions/updateAlert";

function AckPopup(props) {
  const [selectedDate, handleDateChange] = useState(null);

  useEffect(() => {
    handleDateChange(props.etaDate);
  }, [props.etaDate]);

  const handleUpdate = () => {
    selectedDate
      ? props.updateStateEtaOrAction(props.id, "ACK", selectedDate)
      : props.updateAlertState(props.id, "ACK");
    handleDateChange(null);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">OPEN -> ACK</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container>
            <Grid item xs={6}>
              <Typography>Expected Rectification Date</Typography>
            </Grid>
            <Grid item xs={6}>
              <KeyboardDateTimePicker
                clearable
                disablePast
                ampm={true}
                fullWidth
                value={selectedDate}
                onChange={handleDateChange}
                inputVariant="outlined"
                format="DD/MM/YYYY HH:mm a"
                helperText={props.etaDate && "(optional)"}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Close
        </Button>
        <Button
          onClick={() => {
            handleUpdate();
            props.handleClose();
          }}
          color="primary"
        >
          ACK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    updateAlertState: (id, newState) => {
      dispatch(updateAlertState(id, newState));
    },
    updateStateEtaOrAction: (id, newState, eta) => {
      dispatch(updateStateEtaOrAction(id, newState, eta));
    }
  };
};

export default connect(null, mapDispatchToProps)(AckPopup);
