import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Popover,
  CardHeader,
  CardActions,
  Divider,
  Button,
  colors,
  IconButton,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

import NotificationList from "./NotificationList";
import NotificationsModal from "./NotificationsModal";

import socket from "../helpers/socket";

// import { NotificationList, EmptyList } from "./components";

function NotificationsPopover(props) {
  let redirectAlert = "/alerts";

  useEffect(() => {
    // Reconnect web socket if disconnected
    if (!socket.connected) {
      socket.connect();
    }
  }, []);

  const { anchorEl, ...rest } = props;
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  function handleClose() {
    setIsOpen(false);
  }
  function handleClick() {
    setIsOpen(true);
  }

  return (
    <>
      <NotificationsModal open={isOpen} handleClose={handleClose} />
      <Popover
        {...rest}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ maxHeight: "80%" }}
      >
        <div className={classes.root}>
          <CardHeader
            title="Notifications"
            classes={{ action: classes.cardActions }}
            action={
              <IconButton
                component={Link}
                to={redirectAlert}
                style={{ padding: 0 }}
              >
                <WarningIcon color="inherit" />
              </IconButton>
            }
          />
          <Divider />
          <NotificationList count="10" />
          {props.notifications.length > 10 && (
            <>
              <Divider />
              <CardActions className={classes.actions}>
                <Button size="small" to="#" onClick={handleClick}>
                  See all
                </Button>
              </CardActions>
            </>
          )}
        </div>
      </Popover>
    </>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    width: 400,
    maxWidth: "100%",
  },
  actions: {
    backgroundColor: colors.grey[50],
    justifyContent: "center",
  },
  cardActions: {
    marginTop: 0,
    marginRight: 0,
  },
}));

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications.notifications,
  };
};

export default connect(mapStateToProps)(NotificationsPopover);
