import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Grid,
  FormControl,
  NativeSelect,
  InputLabel,
  TextField,
  Divider,
  Typography
} from "@material-ui/core";

import { loadAlertsRectAction } from "../../../actions/loadAlertsRectAction";

import {
  updateEtaOrAction,
  updateStateEtaOrAction
} from "../../../actions/updateAlert";

function RectPopupWithReason(props) {
  const [action, setAction] = useState(null);
  const [reason, setReason] = useState(null);

  useEffect(() => {
    props.loadAlertsRectAction();
  }, []);

  useEffect(() => {
    setReason(props.reason);
    setAction(props.action);
  }, [props]);

  const handleUpdate = () => {
    props.updateEtaOrAction(props.id, "RECT", action, reason);
  };

  const handleChangeAction = event => {
    setAction(event.target.value);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">ACK -> RECT</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container justify="center" alignItems="center">
            <Grid item xs={6}>
              <Typography>Rectification Action</Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <InputLabel htmlFor="reason-native-helper">Reason</InputLabel>
                <NativeSelect
                  value={action}
                  onChange={handleChangeAction}
                  inputProps={{
                    name: "reason",
                    id: "reason-native-helper"
                  }}
                >
                  <option value="" />
                  {props.rectActionList.map((item, key) => {
                    return (
                      <option value={item.alerts_rectification_action_id}>
                        {item.alerts_rectification_action}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Typography>Rectification Action Change Reason</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-dense-multiline"
                margin="dense"
                variant="outlined"
                multiline
                fullWidth
                rowsMax="4"
                rows="4"
                helperText="(optional)"
                value={reason}
                onChange={e => setReason(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Close
        </Button>
        <Button
          onClick={() => {
            handleUpdate();
            props.handleClose();
          }}
          color="primary"
        >
          RECT
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    rectActionList: state.alertsRectAction.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadAlertsRectAction: () => {
      dispatch(loadAlertsRectAction());
    },
    updateEtaOrAction: (id, state, action, reason = null) => {
      dispatch(updateEtaOrAction(id, state, action, reason));
    },
    updateStateEtaOrAction: (id, newState, action) => {
      dispatch(updateStateEtaOrAction(id, newState, action));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RectPopupWithReason);
