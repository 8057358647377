import {
  LOAD_ALERTSTABLE,
  LOAD_ALERTSTABLE_SUCCESS,
  LOAD_ALERTSTABLE_ERROR
} from "../actions/types";

export const initialState = {
  loading: true,
  error: false,
  alertsHeading: {},
  alertsData: []
};

function alertsTableReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_ALERTSTABLE:
      return {
        ...state,
        error: false,
        loading: true
      };
    case LOAD_ALERTSTABLE_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        alertsHeading: action.payload.data.alert_heading,
        alertsData: action.payload.data.alert_data
      };
    case LOAD_ALERTSTABLE_ERROR:
      return {
        ...state,
        error: true,
        loading: true
      };
    default:
      return state;
  }
}

export default alertsTableReducer;
