import axios from "axios";

import {
  UPDATE_ALERT_STATE,
  UPDATE_ALERT_STATE_SUCCESS,
  UPDATE_ALERT_STATE_ERROR,
  UPDATE_ALERT_ETA_OR_REASON,
  UPDATE_ALERT_ETA_OR_REASON_SUCCESS,
  UPDATE_ALERT_ETA_OR_REASON_ERROR,
  UPDATE_ALERT_STATE_ETA_OR_REASON,
  UPDATE_ALERT_STATE_ETA_OR_REASON_SUCCESS,
  UPDATE_ALERT_STATE_ETA_OR_REASON_ERROR
} from "./types";

import { loadAlertsTable } from "./loadAlertsTable";
import { loadAlertsChart } from "./loadAlertsChart";

export const updateAlertState = (id, newState) => {
  return dispatch => {
    dispatch({
      type: UPDATE_ALERT_STATE
    });
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
    const request = {
      alert_id: id,
      resolve_changes: 1, // To change only the state
      new_state: newState
    };

    // console.log("updateAlets: " + JSON.stringify(request));

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/alertaction`, request, { headers })
      .then(({ data }) => {
        const payload = {
          data: data
        };
        dispatch({
          type: UPDATE_ALERT_STATE_SUCCESS,
          payload
        });
        dispatch(loadAlertsTable());
        dispatch(loadAlertsChart());
      })
      .catch(err => {
        console.log("ERROR! [updateAlerts] " + err.message);
        const payload = {
          errorMessage: err.message
        };
        dispatch({
          type: UPDATE_ALERT_STATE_ERROR,
          payload
        });
      });
  };
};

export const updateStateEtaOrAction = (id, newState, etaOrAction) => {
  return dispatch => {
    dispatch({
      type: UPDATE_ALERT_STATE_ETA_OR_REASON
    });
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
    let request;
    if (newState === "ACK")
      request = {
        alert_id: id,
        resolve_changes: 3, // To update the state and details
        new_state: "ACK",
        new_rect_eta: etaOrAction
      };
    else if (newState === "RECT")
      request = {
        alert_id: id,
        resolve_changes: 3, // To update the state and details
        new_state: "RECT",
        new_rect_action_id: etaOrAction
      };

    // console.log("updateAlets: " + JSON.stringify(request));

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/alertaction`, request, { headers })
      .then(({ data }) => {
        const payload = {
          data: data
        };
        dispatch({
          type: UPDATE_ALERT_STATE_ETA_OR_REASON_SUCCESS,
          payload
        });
        dispatch(loadAlertsTable());
        dispatch(loadAlertsChart());
      })
      .catch(err => {
        console.log("ERROR! [updateAlerts] " + err.message);
        const payload = {
          errorMessage: err.message
        };
        dispatch({
          type: UPDATE_ALERT_STATE_ETA_OR_REASON_ERROR,
          payload
        });
      });
  };
};

export const updateEtaOrAction = (id, state, etaOrAction, reason = null) => {
  return dispatch => {
    dispatch({
      type: UPDATE_ALERT_ETA_OR_REASON
    });
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };
    let request;
    if (state === "ACK")
      request = {
        alert_id: id,
        resolve_changes: 2, // To update the details
        new_rect_eta: etaOrAction,
        new_rect_eta_reason: reason
      };
    else if (state === "RECT")
      request = {
        alert_id: id,
        resolve_changes: 2, // To update the details
        new_rect_action_id: etaOrAction,
        new_rect_action_reason: reason
      };

    // console.log("updateAlets: " + JSON.stringify(request));

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/alertaction`, request, { headers })
      .then(({ data }) => {
        const payload = {
          data: data
        };
        dispatch({
            type: UPDATE_ALERT_ETA_OR_REASON_SUCCESS,
            payload
          });
        dispatch(loadAlertsTable());
        dispatch(loadAlertsChart());
      })
      .catch(err => {
        console.log("ERROR! [updateAlerts] " + err.message);
        const payload = {
          errorMessage: err.message
        };
        dispatch({
          type: UPDATE_ALERT_ETA_OR_REASON_ERROR,
          payload
        });
      });
  };
};
