import React from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "../../../theme/icon";

import WarehouseStatusCard from "./WarehouseStatusCard";

const WarehouseBlock = React.forwardRef((props, ref) => {
  const { critical, icon, title, data } = props;
  const classes = useStyles();

  var similiarCard = "",
    count = 0;

  return (
    <div ref={ref} className={classes.root}>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Icon icon={icon} size="30" color="black" />
          <span className={classes.title}>{title}</span>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", padding: "10px" }}>
          {data.map((item, key) => {
            // Split rows based on Sensor Name
            let flexFill = false;
            count++;
            if (similiarCard !== item["Sensor Name"]) {
              if (key !== 0 && count > 1) flexFill = true;
              count = 0;
            }
            similiarCard = item["Sensor Name"];

            // Set Sensor Name
            let reTitle = item["Sensor Alias"]
              ? item["Sensor Alias"]
              : item["Sensor Name"];

            let redirect;
            if (item["Metric Sub-Type"] === "Temperature")
              redirect = "/metric/temp/" + item["Sensor Id"];
            else if (item["Metric Sub-Type"] === "RH")
              redirect = "/metric/rh/" + item["Sensor Id"];
            else if (
              item["Metric Sub-Type"] === "EM Main - MTD" ||
              item["Metric Sub-Type"] === "EM Main - Last 24 hours" ||
              item["Metric Sub-Type"] === "EM Main - Last 1 hour"
            )
              redirect = "/metric/power/" + item["Sensor Id"];
            else if (item["Metric Type"] === "Doors")
              redirect = "/metric/door/" + item["Sensor Id"];
            else if (item["Metric Type"] === "Water tank")
              redirect = "/metric/watertank/" + item["Sensor Id"];
            else if (
              item["Metric Sub-Type"] === "DG Run Hours - MTD" ||
              item["Metric Sub-Type"] === "DG Run Hours - Last 24 hours" ||
              item["Metric Sub-Type"] === "DG Run Hours - Last 1 hour"
            )
              redirect = "/metric/dg/" + item["Sensor Id"];
            else if (
              item["Metric Sub-Type"] === "Solar Energy Total - MTD" ||
              item["Metric Sub-Type"] ===
                "Solar Energy Total - Last 24 hours" ||
              item["Metric Sub-Type"] === "Solar Energy Total - Last 1 hour"
            )
              redirect = "/metric/solar/" + item["Sensor Id"];
            else if (item["Metric Type"] === "Fire")
              redirect = "/metric/fire/" + item["Sensor Id"];

            return (
              <>
                {flexFill && <div style={{ flexGrow: 1, width: "100%" }} />}
                <Box
                  component={redirect ? Link : "div"}
                  to={redirect}
                  style={{ textDecoration: "none" }}
                >
                  <WarehouseStatusCard
                    key={key}
                    icon={
                      <Tooltip title={item["Metric Sub-Type"]}>
                        <span>
                          <Icon
                            icon={item.Icon}
                            size="40"
                            color="white"
                            className={`${
                              item.State === "CRITICAL_ACTIVE"
                                ? classes.statusIconAnimate
                                : ""
                            }`}
                          />
                        </span>
                      </Tooltip>
                    }
                    value={item.Value}
                    unit={item.Unit}
                    status={item.State}
                    title={reTitle}
                    critical={critical}
                    fontSize={item.fontSize}
                  />
                </Box>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexShrink: 0,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "10px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "flex-end",
    paddingLeft: "20px",
  },
  title: {
    paddingLeft: "10px",
    fontWeight: "bold",
    fontSize: "20px",
  },
  statusIconAnimate: {
    animationDuration: ({ duration }) => `${1}s`,
    animationIterationCount: "infinite",
    animationName: `$fadeAnimation`,
  },
  "@keyframes fadeAnimation": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
}));

export default WarehouseBlock;
