import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Collapse, List, ListItem, ListItemText } from "@material-ui/core";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import LocationItem from "./LocationItem";

function LocationList(props) {
  const [open, setOpen] = useState(false);
  const [locations, setLocations] = useState({});

  useEffect(() => {
    if (props.locationData) {
      var result = props.locationData.reduce((res, obj) => {
        let [region] = obj.name.split("_");
        if (res[region]) res[region].push(obj);
        else res[region] = [obj];
        return res;
      }, {});
      setLocations(result);
    }
  }, [props.locationData]);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText primary="View by Location" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {Object.keys(locations).map((item, key) => {
            return (
              <LocationItem name={item} location={locations[item]} key={key} />
            );
          })}
        </List>
      </Collapse>
    </>
  );
}

const mapStateToProps = state => {
  return {
    locationData: state.locations.data,
    locationIsLoading: state.locations.loading
  };
};

export default connect(mapStateToProps, null)(LocationList);
