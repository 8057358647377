import {
  LOAD_SUMMARY,
  LOAD_SUMMARY_SUCCESS,
  LOAD_SUMMARY_ERROR
} from "../actions/types";

export const initialState = {
  data: {
    emergencies: null,
    metrics: null,
    power: null,
    attendance: null
  },
  loading: true,
  error: false
};

function summaryReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SUMMARY:
      return { ...state, data: {}, loading: true, error: false };
    case LOAD_SUMMARY_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false
      };
    case LOAD_SUMMARY_ERROR:
      return {
        ...state,
        data: {},
        loading: true,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
}

export default summaryReducer;
