import { combineReducers } from "redux";

import roleAccessReducer from "./roleAccessReducer";
import locationsReducer from "./locationsReducer";
import locationStateReducer from "./locationStateReducer";
import notificationsReducer from "./notificationsReducer";
import summaryReducer from "./summaryReducer";
import warehouseReducer from "./warehouseReducer";
import unitReducer from "./unitReducer";
import powerReducer from "./powerReducer";
import metricChartReducer from "./metricChartReducer";
import metricReportReducer from "./metricReportReducer";
import alertsChartReducer from "./alertsChartReducer";
import alertsTableReducer from "./alertsTableReducer";
import alertsRectActionReducer from "./alertsRectActionReducer";
// import alertsActionReducer from "./alertsActionReducer";

const rootReducer = combineReducers({
  roleAccess: roleAccessReducer,
  locations: locationsReducer,
  locationState: locationStateReducer,
  notifications: notificationsReducer,
  summary: summaryReducer,
  warehouse: warehouseReducer,
  unit: unitReducer,
  power: powerReducer,
  metricChart: metricChartReducer,
  metricReport: metricReportReducer,
  alertsChart: alertsChartReducer,
  alertsTable: alertsTableReducer,
  alertsRectAction: alertsRectActionReducer,
  // alertsAction: alertsActionReducer
});

export default rootReducer;
