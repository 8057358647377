import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";
import NotificationList from "./NotificationList";

function NotificationsModal(props) {
  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="md"
      onClose={props.handleClose}
      //   scroll={scroll}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
       Notifications
      </DialogTitle>
      <DialogContent>
        <NotificationList />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NotificationsModal;
