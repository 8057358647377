import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Divider,
  Typography,
  Box,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotificationsIcon from "@material-ui/icons/Notifications";

import Icon from "../theme/icon";
import Logo from "../assets/genesis-logo-alt.png";
import LogoAlt from "../assets/favicon-black.png";

import NotificationPopover from "./NotificationsPopover";

import { updateNotificationsCount } from "../actions/updateNotificationsCount";

function TopBar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);

  function handleNotificationsOpen(event) {
    setAnchorEl(event.currentTarget);
    if (props.unreadNotificationsCount) {
      // Reset notifications count
      updateNotificationsCount(); // not a dispatchable fn
    }
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  return (
    <div className={classes.grow}>
      <AppBar
        position="absolute"
        color="primary"
        className={
          props.emergencyOpenCount + props.emergencyAckCount > 0 &&
          classes.topBarBgColorAnimate
        }
      >
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleToggle(true)}
          >
            <MenuIcon />
          </IconButton>
          <Box
            component="div"
            display={{ xs: "none", md: "flex" }}
            alignItems="center"
          >
            <Link to="/">
              <img src={Logo} alt="GENESIS" height="40" />
            </Link>
            <Divider
              orientation="vertical"
              spacing={3}
              style={{
                height: "50px",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            />
          </Box>
          <Box
            component="div"
            display={{ xs: "flex", md: "none" }}
            alignItems="center"
            style={{
              height: "50px",
              // marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <Link to="/">
              <img src={LogoAlt} alt="GENESIS" height="40" />
            </Link>
          </Box>
          <Typography
            variant="h4"
            className={classes.title}
            color="inherit"
            style={{ textTransform: "uppercase", letterSpacing: "2px" }}
          >
            {props.title}
          </Typography>

          <div className={classes.grow} />
          {props.emergencyOpenCount +
            props.emergencyAckCount +
            props.emergencyRectCount >
            0 && (
            <IconButton
              color="inherit"
              aria-haspopup="true"
              className={
                props.emergencyOpenCount + props.emergencyAckCount > 0 &&
                classes.statusIconAnimate
              }
              onClick={() => {
                props.history.push("/alerts");
              }}
            >
              <Badge
                badgeContent={
                  props.emergencyOpenCount +
                  props.emergencyAckCount +
                  props.emergencyRectCount
                }
                color={
                  props.emergencyOpenCount + props.emergencyAckCount > 0
                    ? "error"
                    : "secondary"
                }
              >
                <Icon
                  icon="alert"
                  color="white"
                  size="22"
                  // className={
                  //   props.emergencyOpenCount
                  //     ? classes.statusCritical
                  //     : classes.statusResolved
                  // }
                />
              </Badge>
            </IconButton>
          )}
          <IconButton
            aria-label="show new notifications"
            color="inherit"
            onClick={handleNotificationsOpen}
            aria-haspopup="true"
          >
            <Badge
              badgeContent={props.unreadNotificationsCount}
              color="secondary"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Box
            component="div"
            display={{ xs: "none", md: "flex" }}
            alignItems="center"
          >
            <Box px={3} textAlign="right">
              <Typography variant="h4" color="inherit">
                {localStorage.getItem("username").split("_")[0].toUpperCase()}
              </Typography>
              <Typography
                variant="h6"
                color="inherit"
                style={{ fontSize: "10px", letterSpacing: "1px" }}
              >
                {localStorage.getItem("username").split("_")[1]
                  ? localStorage.getItem("username").split("_")[1].toUpperCase()
                  : ""}
              </Typography>
            </Box>
            <Link to="/logout" style={{ color: "#fff" }}>
              <IconButton edge="end" aria-label="Logout" color="inherit">
                <PowerSettingsNewIcon />
              </IconButton>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <NotificationPopover
        isMenuOpen={isMenuOpen}
        anchorEl={anchorEl}
        handleMenuClose={handleMenuClose}
        onClose={handleMenuClose}
        open={isMenuOpen}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
    },
  },
  notificationContainer: {
    maxHeight: "500px",
    // maxWidth: "500px"
  },
  button: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: "0.5px",
    float: "right",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  statusIconAnimate: {
    animationDuration: ({ duration }) => `${1}s`,
    animationIterationCount: "infinite",
    animationName: `$fadeAnimation`,
  },
  // statusCritical: {
  //   backgroundColor: theme.palette.status.OUT_OF_RANGE,
  // },
  // statusResolved: {
  //   MuiBadge: {
  //     backgroundColor: theme.palette.status.RESOLVED,
  //   },
  // },
  topBarBgColorAnimate: {
    animationDuration: ({ duration }) => `${1}s`,
    animationIterationCount: "infinite",
    animationName: `$colorAnimation`,
  },
  "@keyframes fadeAnimation": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  "@keyframes colorAnimation": {
    from: { backgroundColor: theme.palette.primary.main },
    to: { backgroundColor: theme.palette.status.OUT_OF_RANGE },
  },
}));

const mapStateToProps = (state) => {
  return {
    emergencyOpenCount: state.notifications.emergencyOpenCount,
    emergencyAckCount: state.notifications.emergencyAckCount,
    emergencyRectCount: state.notifications.emergencyRectCount,
    unreadNotificationsCount: state.notifications.unread,
  };
};

export default withRouter(connect(mapStateToProps, null)(TopBar));
